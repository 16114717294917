export const backgroundColorPlugin = {
  id: 'custom_canvas_background_color',
  beforeDraw: chart => {
    const ctx = chart.canvas.getContext('2d');
    const chartArea = chart.chartArea;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = 'white';
    ctx.fillRect(
      chartArea.left,
      chartArea.top,
      chartArea.right - chartArea.left,
      chartArea.bottom - chartArea.top
    );
    ctx.restore();
  },
};

export const chartShadowPlugin = {
  id: 'chartShadow',
  beforeDraw: chart => {
    const ctx = chart.canvas.getContext('2d');
    const chartArea = chart.chartArea;
    ctx.save();
    ctx.shadowColor = 'rgb(170, 173, 200)';
    ctx.shadowBlur = 5;
    ctx.shadowOffsetY = 3;
    ctx.shadowOffsetX = 3;

    ctx.fillStyle = 'white';
    ctx.fillRect(
      chartArea.left,
      chartArea.top,
      chartArea.right - chartArea.left,
      chartArea.bottom - chartArea.top
    );
    ctx.restore();
  },
};

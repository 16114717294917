import { HttpResponse } from '@angular/common/http';

import { BitfRestEnvelopeMapper } from '@bitf/core/parsers/rest-parser';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

import {
  IBitfApiPagination,
  IBitfApiPPaginationResponse,
  IBitfApiResponse,
  IBitfEnvelopeMapperData,
} from '@common/interfaces';

export class AppEnvelopeMapper extends BitfRestEnvelopeMapper {
  map({ req, event }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    event = super.map({ req, event });
    const mappedBody: IBitfApiResponse<any> = event.body;
    const { content, pagination, metadata } = event.body.originalBody;

    if (metadata) {
      mappedBody.metadata = metadata;
    }

    if (content) {
      mappedBody.content = content;
    }

    if (pagination) {
      mappedBody.pagination = pagination;
    }

    return event.clone({
      body: mappedBody,
    });
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { ECurrency, ESummaryCardType } from '@enums';

@Component({
  selector: 'hero-no-connection-card',
  templateUrl: './no-connection-card.component.html',
  styleUrls: ['./no-connection-card.component.scss'],
})
export class NoConnectionCardComponent implements OnInit {
  @Input() imgSrc: string;
  @Input() currency: ECurrency = ECurrency.EUR;
  @Input() label: string = null;
  @Input() value: number;
  @Input() index: number;
  @Input() type: ESummaryCardType = ESummaryCardType.VALUE;
  @Input() negativeIsExpected = false;
  @Input() showFocus = false;

  eSummaryCardType = ESummaryCardType;

  constructor() {}

  ngOnInit(): void {}
}

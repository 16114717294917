import { Injectable, Injector } from '@angular/core';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { Campaign, CampaignsCounters } from '@models';
import { IBitfApiRequest, IBitfApiResponse, ISearchable, ISearchResult, ISearchResultSet } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class CampaignsService extends BitfApiService implements ISearchable {
  constructor(public injector: Injector) {
    super('campaigns', injector);
  }

  search(keyword): Observable<ISearchResultSet> {
    return this.request<Campaign[]>({ path: '/search', method: 'GET', queryParams: { keyword } }).pipe(
      map(response => {
        const searchResults: ISearchResult[] = response.content.map(campaign => {
          return {
            label: campaign.name,
            url: '/campaigns/preview/' + campaign.id,
          } as ISearchResult;
        });
        return {
          searchResults,
          label: bitfToTranslate('SEARCH.LABEL.CAMPAIGNS'),
        } as ISearchResultSet;
      })
    );
  }

  getCounters(request: IBitfApiRequest): Observable<IBitfApiResponse<CampaignsCounters>> {
    return this.request<CampaignsCounters>({
      ...request,
      path: '/count',
      method: 'GET',
      modelMapper: 'campaigns-counters',
    });
  }
}

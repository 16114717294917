import { Component, forwardRef, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { IHeroChip } from '@web/interfaces';

const CUSTOM_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InputWithChipsComponent),
  multi: true,
};

@Component({
  selector: 'hero-input-with-chips',
  templateUrl: './input-with-chips.component.html',
  styleUrls: ['./input-with-chips.component.scss'],
  providers: [CUSTOM_CONTROL_VALUE_ACCESSOR],
})
export class InputWithChipsComponent implements OnInit, ControlValueAccessor, OnDestroy {
  inputControl: FormControl = new FormControl();
  chips: IHeroChip[] = [];

  subscription: Subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.subscription.add(
      this.inputControl.valueChanges.pipe(tap(() => this.processInputValue())).subscribe()
    );
  }

  writeValue(value: string[]) {
    this.chips = value
      ? value.map(val => {
          return {
            label: val,
          };
        })
      : [];
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onBlur() {
    this.onTouchedCallback();
  }

  onChipRemove(chipToRemove: IHeroChip) {
    this.chips = this.chips.filter(chip => chip.label !== chipToRemove.label);
    this.updateControlValue();
  }

  onEnterPress() {
    if (this.inputControl.value) {
      this.createNewChip();
    }
  }

  private processInputValue() {
    const value = this.inputControl.value;

    if (value.includes(',')) {
      this.createNewChip();
      return;
    }

    if (value) {
      if (!this.chips[0]) {
        this.chips.unshift({ label: value });
      } else {
        this.chips[0].label = value;
      }
      this.updateControlValue();
    }
  }

  private createNewChip() {
    this.chips.unshift({ label: '' });
    this.inputControl.setValue('');

    this.updateControlValue();
  }

  private updateControlValue() {
    this.onChangeCallback(this.chips.map(chip => chip.label).filter(value => value));
  }

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (arg: any) => void = () => {};

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

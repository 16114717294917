import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { IBitfDialog, IBitfDialogRef, IBitfCloseEvent } from '@interfaces';
import { EBitfCloseEventStatus } from '@enums';

import { INgbModalConfig } from './ng-bootstrap-dialog.interface';

@Injectable({
  providedIn: 'root',
})
export class NgBootstrapDialogService implements IBitfDialog<INgbModalConfig> {
  defaultModalOptions: Partial<NgbModalOptions> = {
    backdrop: 'static' as 'static',
    centered: true,
    animation: false,
  };

  // NOTE: set modalOptions.backdrop = true to close modal on click outside

  constructor(private ngbModalService: NgbModal) {}

  open(component: any, config: INgbModalConfig): IBitfDialogRef {
    const dialogRef = this.ngbModalService.open(
      component,
      Object.assign({}, this.defaultModalOptions, config.modalOptions)
    );

    const bitfDialogRef: IBitfDialogRef = {
      afterClosed: () => {
        return new Observable<any>(observer => {
          dialogRef.result
            .then(result => {
              observer.next(result);
              observer.complete();
            })
            .catch(error => {
              observer.error(error);
              observer.complete();
            });
        });
      },
      close: (dialogResult: IBitfCloseEvent<any>) => {
        dialogRef.close(dialogResult);
      },
    };

    Object.assign(dialogRef.componentInstance, { bitfDialogRef, ...config.data });
    return bitfDialogRef;
  }

  closeAll() {
    this.ngbModalService.dismissAll({ status: EBitfCloseEventStatus.CLOSE });
  }
}

export enum EBitfFormControlValidatorsKeys {
  min = 'min',
  max = 'max',
  required = 'required',
  requiredTrue = 'requiredTrue',
  email = 'email',
  minLength = 'minLength',
  maxLength = 'maxLength',
  pattern = 'pattern',
  nullValidator = 'nullValidator',
}

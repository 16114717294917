import { Component, Input, OnInit } from '@angular/core';

import { StoreService } from '@common/core/services';

import { IHeroDropdownItem, IDrvProfile } from '@interfaces';
import { EInsightCardType } from '@web/enums';

@Component({
  selector: 'hero-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() accountMenuItems: IHeroDropdownItem[] = [];
  @Input() companyMenuItems: IHeroDropdownItem[] = [];

  eInsightCardType = EInsightCardType;

  isOpen = false;
  isCompanyOpen = false;
  routerUrl = null;
  company: IDrvProfile = {
    name: 'Sintra Digital Business',
    domain: 'sintra.eu',
  };

  get user() {
    return this.storeService.store.user;
  }

  get insights() {
    return this.storeService.store.insights;
  }

  get customer() {
    return this.storeService.store.currentCustomer;
  }

  constructor(public storeService: StoreService) {}

  ngOnInit(): void {}

  toggleDropdown(event) {
    event.preventDefault();
    this.isOpen = !this.isOpen;
  }

  toggleCompanyDropdown(event) {
    event.preventDefault();
    this.isCompanyOpen = !this.isCompanyOpen;
  }

  dropdownClickHandler(item: IHeroDropdownItem) {
    if (item.func) {
      item.func();
    }
  }

  isInsightVisible(type: EInsightCardType) {
    if (!window.location.pathname.includes('insights')) {
      return false;
    }
    const {
      types,
      filters: { showInsights },
    } = this.insights;

    if (!showInsights) {
      return false;
    }

    return types.includes(type);
  }

  isUserFilled() {
    return this.user.firstName && this.user.lastName && this.user.role;
  }

  trackByFn(index: number) {
    return index;
  }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'hero-common-cells-templates',
  templateUrl: './common-cells-templates.component.html',
  styleUrls: ['./common-cells-templates.component.scss'],
})
export class CommonCellsTemplatesComponent implements OnInit {
  @ViewChild('rowNumberTemplate', { static: true }) rowNumberTemplate: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}

import { Injectable } from '@angular/core';

import { BitfLoaderService } from '@bitf/services/loader/bitf-loader.service';

@Injectable({
  providedIn: 'root',
})
export class LoaderService extends BitfLoaderService {
  constructor() {
    super();
  }
}

export enum EIndustries {
  AUTOMOTIVE = 'AUTOMOTIVE',
  BEAUTY = 'BEAUTY',
  PERSONAL_CARE = 'PERSONAL-CARE',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FASHION_AND_APPAREL = 'FASHION-AND-APPAREL',
  ELECTRONICS = 'ELECTRONICS',
  FURNITURE = 'FURNITURE',
  HANDCRAFTS = 'HANDCRAFTS',
  JEWELRY = 'JEWELRY',
  HOUSEHOLD_GOODS = 'HOUSEHOLD-GOODS',
  PAINTING = 'PAINTING',
  PHOTOGRAPHY = 'PHOTOGRAPHY',
  RESTAURANTS = 'RESTAURANTS',
  GEOCERIES = 'GEOCERIES',
  SPORTS = 'SPORTS',
  TOYS = 'TOYS',
  SERVICES = 'SERVICES',
  VIRTUAL_SERVICES = 'VIRTUAL-SERVICES',
  HEALTH = 'HEALTH',
  PHARMACY = 'PHARMACY',
  OTHER = 'OTHER',
}

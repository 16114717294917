<a [href]="url"
  target="_blank"
  *ngIf="metadata && (image || metadata?.ogSiteName || metadata?.ogDescription)"
  class="drv-metadata-preview mt-3">
  <img *ngIf="image"
    [src]="image.src"
    alt="">
  <div class="drv-metadata-preview__description">
    <h5 class="drv-metadata-preview__url">
      {{ urlWithoutProtocol }}
    </h5>
    <h4 class="drv-metadata-preview__title">
      {{ (metadata?.ogSiteName || metadata?.ogTitle || '') | truncate: 35 }}
    </h4>
    <p class="drv-metadata-preview__paragraph">{{ (metadata?.ogDescription || '') | truncate: 85 }}</p>
  </div>
</a>

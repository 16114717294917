import { BitfFile } from '@bitf/core/models';

import { SuperModel } from './super-model.model';

export class Campaign extends SuperModel {
  name?: string;
  productDescription?: string;
  productCategory?: string;
  landingPageUrl?: string;
  competitors?: string[];
  locations?: string[];
  minAge?: number;
  maxAge?: number;
  gender?: string;
  languages?: string[];
  interests?: string[];
  searchQueries?: string[];
  copyMainDescription?: string;
  copyHeadline?: string;
  copyAdditionalDetails?: string;
  dailyBudget?: string;
  currency?: string;
  objective?: string;
  brandingGoal?: string;
  brandingGoalNumber?: number;
  files: BitfFile[];

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {
      name: this.name,
      productDescription: this.productDescription,
      landingPageUrl: this.landingPageUrl,
      competitors: this.competitors,
      locations: this.locations,
      minAge: this.minAge,
      maxAge: this.maxAge,
      gender: this.gender,
      languages: this.languages,
      searchQueries: this.searchQueries,
      copyMainDescription: this.copyMainDescription,
      copyHeadline: this.copyHeadline,
      copyAdditionalDetails: this.copyAdditionalDetails,
      dailyBudget: this.dailyBudget,
      currency: this.currency,
      objective: this.objective,
    };
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { BitfFormControl } from '@bitf/core/form';

@Component({
  selector: 'hero-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class HeroValidationMessageComponent implements OnInit {
  @Input() formControl: BitfFormControl;
  @Input() inputElement: HTMLElement;

  constructor() {}

  ngOnInit(): void {}
}

import {
  Directive,
  Input,
  ElementRef,
  ViewContainerRef,
  ComponentRef,
  ComponentFactoryResolver,
  TemplateRef,
} from '@angular/core';

import { AbstractControl } from '@angular/forms';

import { BitfFormControl } from '@bitf/core/form';
// tslint:disable-next-line:max-line-length
import { HeroValidationMessageComponent } from './validation-message.component';

@Directive({
  selector: '[heroValidationMessage]',
})
export class HeroValidationMessageDirective {
  el: ElementRef;

  @Input('heroValidationMessage')
  set formControl(formControl: AbstractControl) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    if (formControl) {
      const cmpFactory = this.cfr.resolveComponentFactory(HeroValidationMessageComponent);
      const errorComponent: ComponentRef<HeroValidationMessageComponent> = this.viewContainer.createComponent(
        cmpFactory
      );
      errorComponent.instance.formControl = formControl as BitfFormControl;
    }
  }

  constructor(
    private viewContainer: ViewContainerRef,
    private cfr: ComponentFactoryResolver,
    private templateRef: TemplateRef<any>
  ) {}
}

import { SuperModel } from './super-model.model';

export class TwoFactorAuthToken extends SuperModel {
  token: string;
  sign: string;
  timestamp: number;
  backToLogin?: boolean;
  message?: string;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      token: this.token,
      sign: this.sign,
      timestamp: this.timestamp,
    };
  }
}

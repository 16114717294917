import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { RemixIconModule } from 'angular-remix-icon';

import { BitfOAuthInterceptor } from '@bitf/interceptors/bitf-oauth-interceptor.service';
import { BitfRetryInterceptor } from '@bitf/interceptors/bitf-retry-interceptor.service';
import { BitfApiUiMessagesInterceptor } from '@bitf/interceptors/bitf-api-ui-messages-interceptor.service';
import { BitfLanguageInterceptor } from '@bitf/interceptors/bitf-language-interceptor.service';
import { BitfApiErrorsInterceptor } from '@bitf/interceptors/bitf-api-errors-interceptor.service';
// tslint:disable-next-line:max-line-length
import { BitfApiEnvelopeMapperInterceptor } from '@bitf/interceptors/bitf-api-envelope-mapper-interceptor.service';

import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';
import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';

import { APP_VERSION } from '@env/version';

import {
  DesktopLayoutComponent,
  MobileLayoutComponent,
  ProtectedLayoutComponent,
  NotFoundComponent,
  WelcomeDesktopComponent,
  ToastMessagesComponent,
} from './components';

import { RiCloseLine } from 'angular-remix-icon';

import { HeroTenantInterceptor } from './interceptors';

const EXPORTS_COMPONENTS = [NotFoundComponent, ToastMessagesComponent, BitfLoaderComponent];
const COMPONENTS = [
  DesktopLayoutComponent,
  MobileLayoutComponent,
  ProtectedLayoutComponent,
  WelcomeDesktopComponent,
];

const REMIX_ICONS = {
  RiCloseLine,
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${APP_VERSION}`);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RouterModule,
    RemixIconModule.configure(REMIX_ICONS),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfOAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfLanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiUiMessagesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiEnvelopeMapperInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfRetryInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeroTenantInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: BitfErrorHandlerService },
  ],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  exports: [...EXPORTS_COMPONENTS],
})
export class CommonCoreModule {}

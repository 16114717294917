import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IDrvPanelTab } from '@common/interfaces';

@Component({
  selector: 'hero-panel-tabs',
  templateUrl: './panel-tabs.component.html',
  styleUrls: ['./panel-tabs.component.scss'],
})
export class PanelTabsComponent implements OnInit {
  @Input() className = '';
  @Input() items: IDrvPanelTab[];
  @Input() selectedItem: IDrvPanelTab = null;
  @Input() showCounter = false;

  @Output() selectItem = new EventEmitter<IDrvPanelTab>();

  constructor() {}

  ngOnInit(): void {}

  onSelect(item: IDrvPanelTab) {
    if (item.disabled) {
      return;
    }
    this.unselectAllItems();
    this.selectedItem = item;

    if (this.selectItem) {
      this.selectItem.emit(item);
    }
  }

  isSelected(item: IDrvPanelTab) {
    return this.selectedItem && this.selectedItem.value === item.value;
  }

  trackByFn(index) {
    return index;
  }

  private unselectAllItems() {
    this.items = this.items.map(item => {
      return {
        ...item,
        isSelected: false,
      };
    });
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent {
  updateLayout() {
    // const data = this.route.snapshot.firstChild.data;
  }
}

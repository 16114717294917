import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'hero-chars-counter',
  templateUrl: './chars-counter.component.html',
  styleUrls: ['./chars-counter.component.scss'],
})
export class CharsCounterComponent implements OnInit {
  @Input() maxNumberOfChars: number;
  @Input() control: AbstractControl;

  constructor() {}

  ngOnInit(): void {}
}

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { getImageSrcByChannel } from '@common/utils/channels.util';

@Component({
  selector: 'hero-channel-box',
  templateUrl: './channel-box.component.html',
  styleUrls: ['./channel-box.component.scss'],
})
export class ChannelBoxComponent implements OnInit {
  @Input() className: string;
  @Input() channel: string;
  @Input() selected: string[];
  @Output() actionClick = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  iconPath(name: string, extention = 'svg') {
    return `assets/images/marketing/${getImageSrcByChannel(name)}`;
  }

  onButtonClick(channel: string) {
    this.actionClick.emit(channel);
  }
}

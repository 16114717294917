<div class="drv-datepicker"
  [ngClass]="className">
  {{ label }}
  <div class="drv-datepicker__dates">
    <div #datepicker
      class="drv-datepicker__trigger"
      [class.open]="isOpen"></div>
    <div class="drv-datepicker__date"
      [class.w-100]="!range"
      [attr.data-label]="'COMMON.LABEL.START_DATE' | translate">
      <div class="drv-datepicker__icon drv-datepicker__icon-from"></div>
      <div class="drv-datepicker-field">
        {{ dates.startDate | heroDate }}
      </div>
    </div>
    <p class="drv-datepicker-separator">{{ 'COMMON.LABEL.TO' | translate }}</p>
    <div class="drv-datepicker__date w-50"
      [attr.data-label]="'COMMON.LABEL.END_DATE' | translate">
      <div class="drv-datepicker__icon drv-datepicker__icon-to"></div>
      <div class="drv-datepicker-field">
        {{ dates.endDate | heroDate }}
      </div>
    </div>
  </div>
  <!-- <div class="drv-datepicker__wrapper">
    <air-datepicker *ngIf="isOpen"
      [airOptions]="options"
      [airDate]="dates.startDate"
      [airEndDate]="dates.endDate"
      (airEndChange)="onEndDateChanged($event)"
      (airChange)="onStartDateChanged($event)"
      [range]="range"></air-datepicker>
  </div> -->
</div>

<!-- (airChange)="onDateChange($event)" -->
<!--  [(airDate)]="currentDate"-->
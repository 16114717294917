import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'bitf-dnd-file',
  templateUrl: './bitf-dnd-file.component.html',
  styleUrls: ['./bitf-dnd-file.component.scss'],
})
export class BitfDndFileComponent implements OnInit {
  @Output()
  dropFiles = new EventEmitter<FileList>();
  @Output()
  fileOver = new EventEmitter<any>();
  @Output()
  fileLeave = new EventEmitter<any>();

  isDragging = false;

  constructor() {}

  ngOnInit() {}

  onDropFiles(event: DragEvent) {
    this.isDragging = false;
    if (event.dataTransfer) {
      const { dataTransfer } = event;
      dataTransfer.dropEffect = 'copy';
      if (this.dropFiles) {
        this.dropFiles.emit(dataTransfer.files);
      }
    }
    this.preventAndStop(event);
  }

  onDragOver(event: Event) {
    if (!this.isDragging) {
      this.isDragging = true;
      this.fileOver.emit(event);
    }
    this.preventAndStop(event);
  }

  onDragLeave(event: Event) {
    if (this.isDragging) {
      this.isDragging = false;
      this.onDragLeave(event);
    }
    this.preventAndStop(event);
  }

  private preventAndStop(event: Event) {
    event.stopPropagation();
    event.preventDefault();
  }
}

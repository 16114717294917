import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { bitfIsCallingApi } from '@bitf/utils/bitf-urls.utils';

import { BITF_CONFIGS } from '@configs';
import { EBitfInterceptors, EBitfParsers } from '@enums';
import { BITF_PARSER_HEADER_NAME } from '@constants';
import { environment } from '@env/environment';

@Injectable()
export class BitfApiEnvelopeMapperInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!bitfIsCallingApi(environment, req)) {
      return next.handle(req);
    }

    if (req.headers.has(EBitfInterceptors.BITF_API_ENVELOPE_MAPPER_INTERCEPTOR)) {
      const newReq = req.clone({
        headers: req.headers.delete(EBitfInterceptors.BITF_API_ENVELOPE_MAPPER_INTERCEPTOR),
      });
      return next.handle(newReq);
    }

    if (req.headers.has(BITF_PARSER_HEADER_NAME)) {
      const parserName: EBitfParsers = req.headers.get(BITF_PARSER_HEADER_NAME) as EBitfParsers;
      const parserStrategy = BITF_CONFIGS.parsers.parserStrategies[parserName];
      let headers = req.headers.delete(BITF_PARSER_HEADER_NAME);

      if (parserStrategy.customHeaders) {
        Object.keys(parserStrategy.customHeaders).forEach(header => {
          headers = headers.append(header, parserStrategy.customHeaders[header]);
        });
      }
      const newReq = req.clone({
        headers,
      });
      return next.handle(newReq).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            event = this.mapEvent({ event, parserStrategy, req });
          }
          return event;
        }),
        catchError(error => {
          if (error instanceof HttpErrorResponse) {
            error = this.mapEvent({ event: error, parserStrategy, req });
          }
          return throwError(error);
        })
      );
    }

    return next.handle(req);
  }

  private mapEvent({ event, parserStrategy, req }) {
    return parserStrategy.envelopeMapper
      ? parserStrategy.envelopeMapper.map({
          parserStrategy,
          req,
          event,
        })
      : event;
  }
}

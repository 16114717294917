<div class="hero-table-menu"
  [ngClass]="className">
  <div class="hero-table-actions__container position-relative"
    (bitfClickOutside)="isListVisible = false">
    <button class="hero-table-actions__toggle btn-single-icon ml-auto"
      (click)="onActionsClick()">
      <rmx-icon name="more-fill"></rmx-icon>
    </button>
    <ul class="hero-table-actions__list"
      [class.d-none]="!isListVisible">
      <li *ngFor="let item of actions; trackBy: trackByFn"
        class="hero-table-actions__item"><a class="hero-table-actions__link"
          [class.hero-item-disabled]="item.disabled"
          (click)="onActionSelect(item)">{{ item.label | translate }}</a></li>
    </ul>
  </div>
</div>
import { SuperModel } from './super-model.model';

import { EAccountStatus, EChannelName } from '@enums';

export class CustomerAdvAccount extends SuperModel {
  channel: EChannelName;
  accountId: string;
  userId: string;
  promotedObjectId: string;
  customerId?: string;
  currency?: string;
  status?: EAccountStatus;
  topLevelAccountId?: string;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      channel: this.channel,
      accountId: this.accountId,
      userId: this.userId,
      promotedObjectId: this.promotedObjectId,
      currency: this.currency,
      topLevelAccountId: this.topLevelAccountId,
    };
  }
}

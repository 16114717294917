import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { Customer } from '@models';
import { ISearchable, ISearchResult, ISearchResultSet } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class CustomersService extends BitfApiService implements ISearchable {
  constructor(public injector: Injector) {
    super('customers', injector);
  }

  search(keyword): Observable<ISearchResultSet> {
    return this.request<Customer[]>({ path: '/search', method: 'GET', queryParams: { keyword } }).pipe(
      map(response => {
        const searchResults: ISearchResult[] = [];
        response.content.forEach(customer => {
          searchResults.push({
            label: customer.companyName,
            url: '/insights/' + customer.id + '/' + customer.companyName,
          } as ISearchResult);
        });
        return {
          searchResults,
          label: bitfToTranslate('SEARCH.LABEL.CUSTOMERS'),
        } as ISearchResultSet;
      })
    );
  }
}

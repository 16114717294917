import { Component, OnInit } from '@angular/core';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { AuthService } from '@common/core/services';

import { IHeroDropdownItem } from '@interfaces';

@Component({
  selector: 'hero-desktop-web-layout',
  templateUrl: './desktop-web-layout.component.html',
  styleUrls: ['./desktop-web-layout.component.scss'],
})
export class DesktopWebLayoutComponent implements OnInit {
  accountMenuItems: IHeroDropdownItem[] = [
    {
      label: bitfToTranslate('HEADER.BUTTON.MY_SETTINGS'),
      value: 'settings',
      url: '/accounts/profile',
    },
    {
      label: bitfToTranslate('HEADER.BUTTON.LOGOUT'),
      value: 'logout',
      func: () => {
        this.authService.signOut();
      },
    },
  ];

  constructor(private authService: AuthService) {}

  ngOnInit(): void {}
}

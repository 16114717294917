import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { BitfFileUploaderComponent } from '@bitf/core/components/ui/file-uploader/bitf-file-uploader.component';
import { BitfFile } from '@bitf/core/models';

@Component({
  selector: 'hero-avatar-file-uploader',
  templateUrl: './avatar-file-uploader.component.html',
  styleUrls: ['./avatar-file-uploader.component.scss'],
})
export class AvatarFileUploaderComponent extends BitfFileUploaderComponent<BitfFile> implements OnInit {
  @Input() selectedFiles: BitfFile[] = [];
  @Output() filesListUpdated = new EventEmitter<BitfFile[]>();

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.files = this.selectedFiles;
    this.maxNumberOfFiles = 1;
  }

  onFileDropped(event: FileList | any) {
    super.onFileDropped(event);

    this.filesListUpdated.emit(this.files);
  }

  removeFile(index: number) {
    super.removeFile(index);

    this.filesListUpdated.emit(this.files);
  }
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  SORTING = 'SORTING',
  DATE_RANGE_FILTER = 'DATE_RANGE_FILTER',
  TIME_UNIT_FILTER = 'TIME_UNIT_FILTER',
  CHANNEL_FILTER = 'CHANNEL_FILTER',
  CAMPAIGNS_FILTERS = 'CAMPAIGNS_FILTERS',
  CAMPAIGNS_STATUS = 'CAMPAIGNS_STATUS',
}

<ngu-carousel #marketingCarousel
  [inputs]="carouselBanner"
  [dataSource]="carouselTileItems$ | async">

  <ngu-item *nguCarouselDef="let item; index as i; let ani = animate"
    [@slider]="ani">
    <div class="bannerStyle">
      <img [src]="item"
        alt="">
      <div class="content">
        <h3>Advertising has never ever been so easy</h3>
        <p class="fs-15">Everything you need in an easy to setup environement. </p>
      </div>
    </div>
  </ngu-item>

  <ul class="drv-carousel-point"
    NguCarouselPoint>
    <li *ngFor="let i of marketingCarousel.pointNumbers; let j = index"
      [class.active]="j == marketingCarousel.activePoint"
      (click)="marketingCarousel.moveTo(j)"></li>
  </ul>

</ngu-carousel>

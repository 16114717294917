import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { IBitfApiAction, IBitfApiResponse } from '@interfaces';
import { AuthResult, TwoFactorAuthToken } from '@models';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthService extends BitfApiService {
  constructor(public injector: Injector) {
    super('auth', injector);
  }

  login(email: string, password: string): Observable<IBitfApiResponse<TwoFactorAuthToken>> {
    return super.request<TwoFactorAuthToken>({
      path: '/login',
      method: 'POST',
      isBodyRaw: true,
      modelMapper: 'two-factor-auth-token',
      body: { email, password },
    });
  }

  verifyCode(
    email: string,
    token: string,
    code: string,
    timestamp: number,
    sign: string
  ): Observable<IBitfApiResponse<AuthResult>> {
    return super.request<AuthResult>({
      path: '/2fa-code',
      method: 'POST',
      isBodyRaw: true,
      modelMapper: 'auth-result',
      body: { email, token, code, timestamp, sign },
    });
  }

  resendCode(
    email: string,
    token: string,
    timestamp: number,
    sign: string
  ): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.request<IBitfApiAction>({
      path: '/code-resending',
      method: 'POST',
      modelMapper: 'action',
      isBodyRaw: true,
      body: { email, timestamp, token, sign },
    });
  }

  renewToken(): Observable<IBitfApiResponse<AuthResult>> {
    return super.request<AuthResult>({
      path: '/renew-token',
      method: 'POST',
      isBodyRaw: true,
      modelMapper: 'auth-result',
    });
  }

  logout(): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.request<IBitfApiAction>({
      path: '/logout',
      method: 'POST',
      modelMapper: 'action',
    });
  }

  resetPassword(
    newPassword: string,
    token: string,
    timestamp: number
  ): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.request<IBitfApiAction>({
      path: '/reset-password',
      method: 'POST',
      modelMapper: 'action',
      body: {
        newPassword,
        token,
        timestamp,
      },
      isBodyRaw: true,
    });
  }

  sendResetPasswordLink(email: string): Observable<IBitfApiResponse<IBitfApiAction>> {
    return super.request<IBitfApiAction>({
      path: '/send-reset-password-link',
      method: 'POST',
      modelMapper: 'action',
      body: {
        email,
      },
      isBodyRaw: true,
    });
  }

  reactivateUser(email: string, token: string, timestamp: string, sign: string) {
    return super.request<IBitfApiAction>({
      path: '/reactivation',
      method: 'POST',
      modelMapper: 'action',
      body: {
        sign,
        token,
        email,
        timestamp: +timestamp,
      },
      isBodyRaw: true,
    });
  }
}

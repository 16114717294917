import { Component, Input, OnInit } from '@angular/core';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

import { IHeroToggleButton } from '@interfaces';
import { ApiCallStateService } from '@services';
import { EApiCallStateNames, EApiRequestPartKeys, ETimeUnit } from '@enums';

@Component({
  selector: 'hero-time-unit-selector',
  templateUrl: './time-unit-selector.component.html',
  styleUrls: ['./time-unit-selector.component.scss'],
})
export class TimeUnitSelectorComponent implements OnInit {
  @Input() apiCallStateNames: EApiCallStateNames[];

  timeUnitsItems: IHeroToggleButton[] = [
    {
      label: bitfToTranslate('BITF.LABEL.DAY'),
      value: ETimeUnit.DAY,
      isSelected: true,
    },
    {
      label: bitfToTranslate('BITF.LABEL.WEEK'),
      value: ETimeUnit.WEEK,
    },
    {
      label: bitfToTranslate('BITF.LABEL.MONTH'),
      value: ETimeUnit.MONTH,
    },
  ];

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {
    this.setSelectedItem();
  }

  onSelectItem(timeUnitItem: IHeroToggleButton) {
    this.onTimeUnitChange(timeUnitItem.value as ETimeUnit);
  }

  private setSelectedItem() {
    const { timeUnit } = this.getRequestPart(this.apiCallStateNames[0]).formValue;
    this.timeUnitsItems = this.timeUnitsItems.map(timeUnitItem => {
      return {
        ...timeUnitItem,
        isSelected: timeUnit === timeUnitItem.value,
      };
    });
  }

  private getRequestPart(apiCallStateName: EApiCallStateNames): BitfApiRequestPart {
    return this.apiCallStateService.getRequestPart(apiCallStateName, EApiRequestPartKeys.TIME_UNIT_FILTER);
  }

  private onTimeUnitChange(timeUnit: ETimeUnit) {
    this.apiCallStateNames.forEach(apiCallStateName => {
      this.getRequestPart(apiCallStateName).formValue = {
        timeUnit,
      };
      this.apiCallStateService.setStore(() => {}, apiCallStateName);
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { IHeroChip } from '@web/interfaces';

@Component({
  selector: 'hero-chips',
  templateUrl: './chips.component.html',
  styleUrls: ['./chips.component.scss'],
})
export class ChipsComponent implements OnInit {
  @Input() items: IHeroChip[];
  @Input() disabledRemoveBtn = false;
  @Input() rounded = true;
  @Output() removeItem = new EventEmitter<IHeroChip>();

  constructor() {}

  ngOnInit(): void {}

  trackByFn(index: number): number {
    return index;
  }

  removeChip(chip: IHeroChip) {
    if (this.removeItem) {
      this.removeItem.emit(chip);
    }
  }
}

import { SuperModel } from './super-model.model';

export class CampaignsCounters extends SuperModel {
  all: number;
  draft: number;
  active: number;
  finished: number;
  failed: number;
  paused: number;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}

import { Directive, EventEmitter, Input, Output, OnInit, HostListener, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[bitfClipboard]',
})
export class BitfClipboardDirective implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('bitfClipboard')
  value: string = null;

  @Output()
  copyEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output()
  errorEvent: EventEmitter<Error> = new EventEmitter<Error>();

  private dom: Document;

  @HostListener('click')
  copyToClipboard() {
    let input: HTMLInputElement = null;
    try {
      input = this.dom.createElement('input');
      input.setAttribute('value', this.value);
      input.style.position = 'absolute';
      input.style.top = '-10000px';
      this.dom.body.appendChild(input);
      input.select();
      const res = this.dom.execCommand('copy');
      setTimeout(() => {
        this.copyEvent.emit();
      });
    } catch (error) {
      this.errorEvent.emit(new Error('The content could not be copied'));
    } finally {
      this.dom.body.removeChild(input);
    }
  }

  constructor(@Inject(DOCUMENT) dom: Document) {
    this.dom = dom;
  }

  ngOnInit() {}
}

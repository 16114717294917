export enum EStatisticsParam {
  CLICKS = 'clicks',
  COST = 'cost',
  CPA = 'cpa',
  CPC = 'cpc',
  CPM = 'cpm',
  CTR = 'ctr',
  E_CPC = 'eCpc',
  E_CPM = 'eCpm',
  IMPRESSIONS = 'impressions',
}

<div class="drv-radio-box"
  [ngClass]="className">
  <input type="radio"
    [id]="item.value"
    [name]="item.name"
    [value]="item.value"
    (change)="onSelect(item)" />
  <div class="drv-radio-container">
    <div class="drv-radio-checkmark"></div>
  </div>
  <label [for]="item.value"
    class="drv-radio-box-label mb-0">
    <span class="drv-radio-box-label__title">{{ item.label | translate }}</span>
    <span class="drv-radio-box-label__description">{{ item.description | translate }}</span>
    <span *ngIf="item.tags"
      class="drv-radio-box-label__tags">
      <span *ngFor="let tag of item.tags; let i = index; trackBy: trackByFn;"
        class="drv-radio-box-label__tag">{{ tag | translate }}</span>
    </span>
  </label>
</div>

import { BITF_CONFIGS } from '@configs';
import { environment } from '@env/environment';
import {
  IBitfApiRequest,
  IBitfApiResponse,
  IBitfPathBuilder,
  IBitfRequestMapper,
  IBitfResponseMapper,
} from '@interfaces';

export class BitfApiHelper {
  constructor(private apiEntity: string, private mockApiUrl, private mockApiParser) {}

  parseRequestParams(requestParams: IBitfApiRequest = {}) {
    return {
      path: this.buildRequestPath(requestParams),
      options: this.mapRequestOptions(requestParams),
      body: this.mapBodyRequest(requestParams),
    };
  }

  // URL
  buildRequestPath(requestParams: IBitfApiRequest = {}) {
    const builtPath = this.getPathBuilder(requestParams).build(requestParams);

    let path = this.getBaseApiUrl(requestParams) + builtPath;
    if (requestParams.path) {
      path += requestParams.path;
    }
    return path;
  }

  getPathBuilder(requestParams: IBitfApiRequest): IBitfPathBuilder {
    return this.getParser('pathBuilder', requestParams) as IBitfPathBuilder;
  }

  getApiUrl(requestParams: IBitfApiRequest = {}): string {
    return requestParams.apiUrl || this.mockApiUrl || environment.apiUrl;
  }

  getBaseApiUrl(requestParams: IBitfApiRequest = {}): string {
    return `${this.getApiUrl(requestParams)}${this.apiEntity}`;
  }

  // REQUEST
  mapRequestOptions(requestParams: IBitfApiRequest = {}) {
    return this.getRequestMapper(requestParams).map(requestParams);
  }

  getRequestMapper(requestParams: IBitfApiRequest = {}): IBitfRequestMapper {
    return this.getParser('requestMapper', requestParams) as IBitfRequestMapper;
  }

  // RESPONSE
  mapEnvelope<T>(apiOputput, requestParams: IBitfApiRequest = {}): IBitfApiResponse<T> {
    return this.getResponseMapper(requestParams).map<T>(apiOputput, this.getModelMapper(requestParams));
  }

  getModelMapper(requestParams: IBitfApiRequest) {
    const { relation, modelMapper } = requestParams;
    return modelMapper || relation || this.apiEntity;
  }

  getResponseMapper(requestParams: IBitfApiRequest = {}): IBitfResponseMapper {
    return this.getParser('responseMapper', requestParams) as IBitfResponseMapper;
  }

  getParser(
    type: 'requestMapper' | 'responseMapper' | 'pathBuilder',
    requestParams: IBitfApiRequest
  ): IBitfRequestMapper | IBitfResponseMapper | IBitfPathBuilder {
    const parserStrategyName =
      this.mockApiParser || requestParams.apiParser || BITF_CONFIGS.parsers.defaultParser;
    const parser = BITF_CONFIGS.parsers.parserStrategies[parserStrategyName];
    if (!parser) {
      throw new Error(`parser not present`);
    }
    return parser[type];
  }

  // BODY
  mapBodyRequest(requestParams: IBitfApiRequest) {
    let body = requestParams.body;
    if (!body) {
      return null;
    }

    if (!requestParams.isBodyRaw) {
      if (body.length) {
        body = body.map(item => item.serialised);
      } else {
        body = body.serialised;
      }
    }

    switch (requestParams.bodyParser) {
      case 'formData':
        const formData = new FormData();
        Object.entries(body as object).forEach(([key, val]) => {
          formData.set(key, val);
        });
        return formData;
      case 'formUrlEncoded':
        const bodyString: string[] = [];
        Object.entries(body).forEach(([key, val]) => {
          bodyString.push(`${key}=${val}`);
        });
        return bodyString.join('&');
      default:
        return body;
    }
  }
}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { TranslateService } from '@ngx-translate/core';
import { CONSTANTS } from '@web/constants';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe extends DatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {
    super('en-US');
  }

  transform(value: Date): any {
    if (!value) {
      return '';
    }
    const now = new Date();
    const minutesDiff = (now.getTime() - value.getTime()) / (1000 * 60);
    const hoursDiff = (now.getTime() - value.getTime()) / (1000 * 3600);

    if (minutesDiff <= 5) {
      return this.translateService.instant(bitfToTranslate('PIPES.DATE_AGO.JUST_NOW'));
    }
    if (minutesDiff <= 60 && minutesDiff > 5) {
      return this.translateService.instant(bitfToTranslate('PIPES.DATE_AGO.IN_LAST_HOUR'));
    }
    if (minutesDiff <= 120 && minutesDiff > 60) {
      return this.translateService.instant(bitfToTranslate('PIPES.DATE_AGO.AN_HOUR_AGO'));
    }
    if (hoursDiff <= 24 && hoursDiff > 2) {
      return this.translateService.instant(bitfToTranslate('PIPES.DATE_AGO.TODAY'));
    }
    if (hoursDiff <= 48 && hoursDiff > 24) {
      return this.translateService.instant(bitfToTranslate('PIPES.DATE_AGO.YESTERDAY'));
    }
    return super.transform(value.toString(), CONSTANTS.dateFormat);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hero-coming-soon-card',
  templateUrl: './coming-soon-card.component.html',
  styleUrls: ['./coming-soon-card.component.scss'],
})
export class ComingSoonCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

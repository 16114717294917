import { AppEnvelopeMapper, AppPathBuilder, AppRequestMapper, AppResponseMapper } from '../core/parsers';

import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  parsers: {
    defaultParser: 'restParser',
    parserStrategies: {
      restParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
  urls: { homePageUrl: '/', signInUrl: '/login', signOutUrl: '/login' },
  pwa: { serviceWorker: { checkUpdateInterval: 30000 }, installPrompt: { askAgainAfterNDays: 10 } },
};

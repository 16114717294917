import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hero-first-connector-card',
  templateUrl: './first-connector-card.component.html',
  styleUrls: ['./first-connector-card.component.scss'],
})
export class FirstConnectorCardComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  trackByFn(index) {
    return index;
  }
}

import { FormControl } from '@angular/forms';

// tslint:disable-next-line:max-line-length
export const bitfEmailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;

export function bitfValidateEmail(formControl: FormControl) {
  const reg = new RegExp(bitfEmailRegex);
  const value = formControl.value && formControl.value.trim ? formControl.value.trim() : formControl.value;
  return reg.test(value) ? false : { email: true };
}

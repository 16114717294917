import { BitfTouchEvent } from './bitf-touch-event.model';

export class BitfTouchEventSnapshot {
  triggerItemId: string;
  startTime: number;
  endTime: number;
  elapsedTime: number;
  events: BitfTouchEvent[];

  constructor(item: Partial<BitfTouchEventSnapshot>) {
    Object.assign(this, item);
  }
}

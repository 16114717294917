import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { getDefaultISODateString, getFirstDayOfMonth, getLastDayOfMonth } from '@bitf/utils/bitf-dates.utils';

import { EApiRequestPartKeys } from '@enums';
import { EBitfApiRequestPartsNames } from '@enums';

export class DateRangeApiRequestPart extends BitfApiRequestPart {
  constructor(defaultStartDate: Date = getFirstDayOfMonth(), defaultEndDate: Date = getLastDayOfMonth()) {
    super(EApiRequestPartKeys.DATE_RANGE_FILTER, EBitfApiRequestPartsNames.QUERY_PARAMS, {
      formValue: {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
      },
    });
  }

  build(): void {
    this.part = undefined;
    if (!this.formValue || !this.formValue.startDate || !this.formValue.endDate) {
      return;
    }
    this.part = {
      startDate: getDefaultISODateString(this.formValue.startDate, 'start'),
      endDate: getDefaultISODateString(this.formValue.endDate, 'end'),
    };
  }
}

<div class="hero-toggle-button">
  <ul class="hero-toggle-button__list">
    <li class="hero-toggle-button__list-item"
      [ngClass]="className"
      [class.selected]="isSelected(item) || item.isSelected"
      [class.disabled]="item.disabled"
      *ngFor="let item of items; trackBy:trackByFn"
      (click)="onSelect(item)">
      {{ item.label | translate }}
    </li>
  </ul>
</div>

<button class="hero-modal-close">
  <rmx-icon name="close-line"
    class="w-100 h-100"
    (click)="close()"></rmx-icon>
</button>
<h1 class="hero-modal-title">{{ title | translate }}</h1>
<div class="hero-modal-content">
  <perfect-scrollbar style="position: relative; max-height: 58vh;">
    <p>Please read these terms and conditions carefully before using Our Service.</p>
    <h3>Interpretation and Definitions</h3>
    <p>The words of which the initial letter is capitalized have meanings defined under the following
      conditions.</p>
    <p>The following definitions shall have the same meaning regardless of whether they appear in singular
      or in
      plural.</p>
    <p><strong>Definitions</strong></p>
    <p>For the purposes of these Terms and Conditions:</p>
    <p>
      • Affiliate means an entity that controls, is controlled by or is under common control with a party,
      where
      “control” means ownership of 50% or more of the shares, equity interest or other securities entitled
      to
      vote for election of directors or other managing authority.<br>
      • Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Agreement) refers to
      Velocity
      Ventures Limited, The Penthouse Tower Business Centre Tower Street, Swatar BKR 4013, Malta.<br>
      • Country refers to: Malta<br>
      • Service refers to the Website.<br>
      • Terms and Conditions (also referred as “Terms”) mean these Terms and Conditions that form the entire
      agreement between You and the Company regarding the use of the Service. This Terms and Conditions
      agreement as been created with the help of Terms and Conditions Generator.<br>
      • Third-party Social Media Service means any services or content (including data, information,
      products or
      services) provided by a third-party that may be displayed, included or made available by the
      Service.<br>
      • Website refers to DRVE, accessible from https://drve.com/<br>
    </p>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minima culpa repudiandae consequatur, placeat
      libero, assumenda nisi eaque explicabo voluptates cum quo sed dolore soluta, aperiam error consectetur
      natus voluptatum voluptate ad repellendus. Deleniti molestiae harum aperiam nihil numquam vel, fugit
      voluptatem ex autem explicabo nam necessitatibus quidem iusto in eveniet quasi consequuntur expedita
      libero, ipsa dolores quisquam ipsum error. Delectus vero porro, voluptatem voluptate sit libero,
      molestiae facere vitae a explicabo rem molestias quam enim, nemo qui iste dolore incidunt. Optio
      molestiae enim, quibusdam adipisci distinctio repellat sapiente aut maiores porro odio ea ad possimus
      quaerat aperiam temporibus a hic nostrum eligendi officiis animi iure rem vel pariatur. Ad, optio ut.
      Tempora maiores consectetur earum sint dicta voluptatem asperiores dolore!</p>
  </perfect-scrollbar>
</div>

<div class="hero-modal-actions">
  <button (click)="onCancel()"
    [class]="noButtonClass">{{ cancelText | translate }}</button>
  <button (click)="onOk()"
    [class]="yesButtonClass">{{ okText | translate }}</button>
</div>
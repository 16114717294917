export enum EColorsRGBA {
  PURPLE_800 = 'rgba(41, 31, 91, 1)',
  YELLOW = 'rgba(255, 203, 25, 1)',
  GREEN = 'rgba(85, 208, 156, 1)',
  RED = 'rgba(219, 86, 107, 1)',
  BLUE = 'rgba(110, 202, 212, 1)',
  ORANGE_400 = 'rgba(255, 160, 0, 1)',
  BLUE_GRAY_400 = 'rgba(170, 173, 200, 1)',
  BLUE_GRAY_350 = 'rgba(227, 228, 238, 1)',
  WHITE = 'rgba(255, 255, 255, 1)',
}

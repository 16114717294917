<div class="drv-campaign-card"
  [class.active]="status === campaignStatus.ACTIVE"
  [class.completed]="status === campaignStatus.COMPLETED"
  [class.canceled]="status === campaignStatus.CANCELED">
  <p class="drv-campaign-card__note">
    {{ 'CAMPAIGNS.CART.AWERNESS' | translate }}
  </p>
  <h4 class="drv-campaign-card__title">{{ title }}</h4>

  <rmx-icon *ngIf="isFavorite; else notFavorite"
    name="star-fill"
    class="star-fill drv-campaign-card__star drv-campaign-card__star--favorite">
  </rmx-icon>
  <ng-template #notFavorite>
    <rmx-icon name="star-line"
      class="star-line drv-campaign-card__star "></rmx-icon>
  </ng-template>

  <!-- GOALS -->
  <div class="drv-campaign-card__info drv-campaign-card__info--goals">
    <p>{{ 'CAMPAIGNS.CART.USER_GOAL' | translate }}</p>
    <p>{{ goalAchieved }}/{{ goal }}</p>
    <div class="drv-progress-bar">
      <div [style.width.%]="goalProgress"
        class="progress progress--goals"></div>
    </div>
  </div>

  <!-- TIME -->
  <div class="drv-campaign-card__info drv-campaign-card__info--remaining">
    <p>
      {{ 'CAMPAIGNS.CART.TIME_REMAINING' | translate }}
    </p>
    <p>{{ timeLeft }} {{ 'CAMPAIGNS.CART.DAYS_LEFT' | translate }}</p>
    <div class="drv-progress-bar">
      <div [style.width.%]="timeProgress"
        class="progress"></div>
    </div>
  </div>

  <!-- BUDGET -->
  <div class="drv-campaign-card__info drv-campaign-card__info--budget">
    <p>{{ 'CAMPAIGNS.CART.BUDGET_LEFT' | translate }}</p>
    <p>
      {{ budgetUsed | currency:currency:'symbol':'1.0' }} /
      {{ budget | currency:currency:'symbol':'1.0' }}
    </p>
    <div class="drv-progress-bar">
      <div [style.width.%]="budgetProgress"
        class="progress"></div>
    </div>
  </div>

  <div class="drv-campaign-card__footer">
    <div class="drv-campaign-card__footer--status">
      {{ label | translate }}
    </div>
    <figure *ngFor="let channel of channels; trackBy: trackByFn"
      class="drv-campaign-card__footer--icon-wrapper">
      <img [src]="getChannelIcon(channel)" />
    </figure>
  </div>
</div>

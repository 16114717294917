import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { bitfIsCallingApi } from '@bitf/utils/bitf-urls.utils';

import { environment } from '@env/environment';

import { StoreService } from '@services';

@Injectable()
export class HeroTenantInterceptor implements HttpInterceptor {
  constructor(private storeService: StoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isCallingLoginEndpoints = req.url.includes('/auth/login');
    const { currentCustomer, currentProject } = this.storeService.store;

    const customerProjectIdsExists = currentCustomer && currentProject;

    if (bitfIsCallingApi(environment, req) && !isCallingLoginEndpoints && customerProjectIdsExists) {
      const headers = req.headers
        .set('X_CUSTOMER_ID', currentCustomer.id)
        .set('X_PROJECT_ID', currentProject.id);
      const authReq = req.clone({
        headers,
      });
      return next.handle(authReq);
    }
    return next.handle(req);
  }
}

<div class="hero-connection-card"
  [class.selected]="isSelected"
  [class.connected]="status === EAccountStatus.CONNECTED"
  (click)="onConnect()"
  (mouseenter)="mouseEnter()"
  (mouseleave)="updateImgOnLeave()">
  <div *ngIf="status"
    class="drv-card-status">
    <rmx-icon class="drv-connector-card__btn-icon"
      [name]="status === EAccountStatus.CONNECTED ? 'check-line' : ''">
    </rmx-icon>
    <!-- [class.error]="status === EAccountStatus.DISCONNECTED" -->
    <!-- [name]="status === EAccountStatus.CONNECTED ? 'check-line' : status === EAccountStatus.DISCONNECTED ? 'error-warning-line' : ''" -->
  </div>
  <button *ngIf="status && status !== EAccountStatus.DISCONNECTED"
    class="btn-connect btn-connect--edit">
    <rmx-icon class="drv-connector-card__btn-icon small"
      name="edit-2-line"></rmx-icon>
  </button>

  <img class="drv-card-img"
    [src]="imgSrc" />
  <h4 class="drv-card-name">{{ 'CONNECTORS.CHANNEL.' + channel | translate }}</h4>
  <p class="drv-card-category">{{ 'CONNECTORS.CATEGORIES.' + category | translate }}</p>
  <button *ngIf="status !== EAccountStatus.CONNECTED"
    [class.connecting]="isConnecting"
    class="btn-connect">
    <div *ngIf="isConnecting">{{ 'CONNECTORS.BUTTON.CONNECTING' | translate }}</div>
    <div *ngIf="!isConnecting">{{ 'COMMON.BUTTON.CONNECT' | translate }}</div>
  </button>

  <p *ngIf="status === EAccountStatus.CONNECTED"
    class="drv-card-info">
    {{ 'CONNECTORS.LABEL.CHANNEL_CONNECTED' | translate }}
  </p>
  <!-- <p *ngIf="status === EAccountStatus.DISCONNECTED"
    class="drv-card-info">{{ 'CONNECTORS.LABEL.CONNECTION_ERROR' | translate }}</p> -->
</div>

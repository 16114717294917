import { Injectable } from '@angular/core';

import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { EBitfSidenavPosition } from '@web/enums';
import { IBitfSidenavContent, IBitfDialogRef } from '@interfaces';

import { DialogsService } from './dialogs.service';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  constructor(private dialogsService: DialogsService) {}

  open(sidenavData: IBitfSidenavContent): IBitfDialogRef {
    const positionClass =
      sidenavData.sidenavOptions.position && sidenavData.sidenavOptions.position === EBitfSidenavPosition.LEFT
        ? ' hero-sidenav--left '
        : ' hero-sidenav--right ';

    const modalOptions: NgbModalOptions = {
      windowClass: 'hero-sidenav ' + positionClass + (sidenavData.sidenavOptions.sidenavClass || ''),
      backdrop: true,
    };

    return this.dialogsService.dialog.open(sidenavData.component, {
      modalOptions,
      data: sidenavData.componentData || {},
    });
  }

  close() {
    this.dialogsService.dialog.closeAll();
  }
}

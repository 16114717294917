import { ECurrency } from '@enums';
import { IStatistic } from '@interfaces';

import { SuperModel } from './super-model.model';

export class Statistics extends SuperModel {
  avgPagesPerSession: IStatistic | number[];
  avgSessionDuration: IStatistic | number[];
  transactionsNumber: IStatistic | number[];
  users: IStatistic | number[];
  bounceRate: IStatistic | number[];
  conversionRate: IStatistic | number[];
  revenue: IStatistic | number[];
  cost: IStatistic | number[];
  currency: ECurrency;
  labels: string[];

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {};
  }
}

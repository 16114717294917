import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';

import { DesktopLayoutComponent, NotFoundComponent, MobileLayoutComponent } from '@common/core/components';
import { AuthGuard, WelcomeGuard } from '@common/core/services/guards';
import { CustomerGuard } from '@core/services/guards/customer.guard';
import { DesktopWebLayoutComponent } from './core/components/desktop-web-layout/desktop-web-layout.component';
import { WebWrapperLayoutComponent } from './core/components/web-wrapper-layout/web-wrapper-layout.component';

const sharedRoutes: Routes = [
  {
    path: 'demo',
    loadChildren: () => import('./modules/demo/demo.module').then(m => m.DemoModule),
    canActivate: [AuthGuard, CustomerGuard],
  },
  {
    path: 'campaigns',
    loadChildren: () => import('./modules/campaigns/campaigns.module').then(m => m.CampaignsModule),
    canActivate: [AuthGuard, CustomerGuard, WelcomeGuard],
  },
  {
    path: 'connectors',
    loadChildren: () => import('./modules/connectors/connectors.module').then(m => m.ConnectorsModule),
    canActivate: [AuthGuard, CustomerGuard, WelcomeGuard],
  },
  {
    path: 'insights',
    loadChildren: () => import('./modules/insights/insights.module').then(m => m.InsightsModule),
    canActivate: [AuthGuard, CustomerGuard, WelcomeGuard],
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule),
    canActivate: [AuthGuard, CustomerGuard, WelcomeGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('../../common/modules/protected/protected.module').then(m => m.ProtectedModule),
    canActivate: [AuthGuard, CustomerGuard, WelcomeGuard],
  },

  {
    path: '**',
    component: NotFoundComponent,
  },
];

// const routes: Routes = [
//   {
//     path: 'm',
//     component: MobileLayoutComponent,
//     children: [...sharedRoutes],
//   },
//   {
//     path: '',
//     component: DesktopLayoutComponent,
//     children: [...sharedRoutes],
//   },
//   {
//     path: '**',
//     component: NotFoundComponent,
//   },
// ];

const routes: Routes = [
  {
    path: '',
    component: WebWrapperLayoutComponent,
    children: [
      { path: '', redirectTo: 'insights', pathMatch: 'full' },
      {
        path: 'welcome',
        loadChildren: () => import('../web/modules/welcome/welcome.module').then(m => m.WelcomeModule),
        canActivate: [AuthGuard, CustomerGuard],
      },
      {
        path: '',
        loadChildren: () => import('../../common/modules/public/public.module').then(m => m.PublicModule),
        data: { disableSignUp: false },
      },
      {
        path: '',
        component: DesktopWebLayoutComponent,
        children: [...sharedRoutes],
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
      // { path: 'm', redirectTo: 'campaigns', pathMatch: 'full' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

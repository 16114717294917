import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

import { CONSTANTS } from '@constants';

@Pipe({
  name: 'heroDate',
})
export class HeroDatePipe extends DatePipe implements PipeTransform {
  constructor() {
    super('en-US');
  }
  transform(value: unknown): any {
    return super.transform(value as string, CONSTANTS.dateFormat);
  }
}

<div class="drv-metric-card">
  <div class="drv-card-info">
    <h5 class="drv-card-info__title">{{ label | translate }}</h5>
    <p class="drv-card-info__value"
      [ngSwitch]="type">
      <ng-container *ngSwitchCase="eSummaryCardType.PERCENTAGE">
        {{ value | number:'1.0-2' }}%
      </ng-container>
      <ng-container *ngSwitchCase="eSummaryCardType.CURRENCY">
        {{ value | currency:currency:'symbol':'1.2-2' }}
      </ng-container>
      <ng-container *ngSwitchCase="eSummaryCardType.NO_DATA">
        --
      </ng-container>
      <ng-container *ngSwitchDefault>{{ value | number: '1.0-2' }}</ng-container>
    </p>
    <figure *ngIf="imgSrc"
      class="drv-card-figure">
      <img class="drv-card-img"
        [src]="'assets/images/marketing/' + imgSrc" />
    </figure>
    <rmx-icon *ngIf="showFocus"
      name="focus-3-line"
      class="rmx-focus"></rmx-icon>
  </div>
  <p *ngIf="index !== undefined"
    class="drv-card-index"
    [class.drv-card-index--minus]="(index < 0 && !negativeIsExpected) || (index > 0 && negativeIsExpected)"
    [class.drv-card-index--plus]="(index > 0 && !negativeIsExpected) || (index < 0 && negativeIsExpected)">
    <span *ngIf="index === 0; else indexTpl">-</span>
    <ng-template #indexTpl>{{ index | heroNumber }}%</ng-template>
  </p>
</div>
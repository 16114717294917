export enum EBitfSidenavPosition {
  START = 'start',
  LEFT = 'left',
  END = 'end',
  RIGHT = 'right',
}

export enum EBitfSidenavMode {
  OVER = 'over',
  PUSH = 'push',
  SIDE = 'side',
}

import { ICampaignsTimeSeries } from '@common/interfaces';
import { SuperModel } from './super-model.model';

export class CampaignsTimeSeries extends SuperModel {
  campaigns: ICampaignsTimeSeries[];

  constructor(data: [] = []) {
    super();
    this.campaigns = data;
  }

  get serialised() {
    return {};
  }
}

<div class="hero-insight-icons">
  <ul class="hero-insight-icons__list">
    <li class="hero-insight-icons__list-item"
      [class.disabled]="item.disabled"
      *ngFor="let item of items; trackBy: trackByFn"
      (click)="switched.emit(item.channel)">
      <img class="hero-insight-icons__icon"
        [src]="item.url"
        [alt]="item.label">
    </li>
  </ul>
</div>
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { bitfIsCallingApi } from '@bitf/utils/bitf-urls.utils';

import { EBitfInterceptors } from '@enums';
import { IBitfApiUiMessage, IBitfApiResponse } from '@interfaces';
import { StoreService } from '@services';

import { environment } from '@env/environment';

@Injectable()
export class BitfApiUiMessagesInterceptor {
  constructor(private storeService: StoreService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!bitfIsCallingApi(environment, req)) {
      return next.handle(req);
    }

    if (req.headers.has(EBitfInterceptors.BITF_API_UI_MESSAGES_INTERCEPTOR)) {
      const newReq = req.clone({
        headers: req.headers.delete(EBitfInterceptors.BITF_API_UI_MESSAGES_INTERCEPTOR),
      });
      return next.handle(newReq);
    }

    return next.handle(req).pipe(
      tap(
        response => {
          if (response instanceof HttpResponse) {
            this.parseUiMessages(response.body);
          }
          return response;
        },
        errorResponse => {
          if (errorResponse instanceof HttpErrorResponse) {
            // Envelop mapper has modified the error response to have a body
            this.parseUiMessages(errorResponse['body']);
          }
        }
      )
    );
  }

  parseUiMessages(apiResponse: IBitfApiResponse<any>) {
    const uiMessages = apiResponse && apiResponse.metadata && apiResponse.metadata.uiMessages;

    if (uiMessages && uiMessages.length) {
      uiMessages.forEach((message: IBitfApiUiMessage) => {
        this.storeService.store.uiMessages$.next({
          type: 'BitfUiMessages',
          strategy: String(message.target),
          payload: message,
        });
      });
    }
  }
}

import { Injectable, Injector, ErrorHandler } from '@angular/core';
import { BitfLoggerService } from '@bitf/services/logger/bitf-logger.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@env/environment';
// FIXME
// import { BitfPWAService } from '@bitf/services';
import { IBitfLoggerParams } from '@interfaces';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

@Injectable({
  providedIn: 'root',
})
export class BitfErrorHandlerService extends ErrorHandler {
  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {
    this.handle(error);
    super.handleError(error);
  }

  handle(errorObj: Error | HttpErrorResponse, loggerParams: IBitfLoggerParams = {}) {
    // FIXME
    // const pwaService = this.injector.get(BitfPWAService);
    const logObj: IBitfLoggerParams & { name?: string } = {};
    if (errorObj instanceof Error) {
      if (
        environment.loggerConfig.logExceptions ||
        (loggerParams.fromDecorator && environment.loggerConfig.logDecoratedExceptions)
      ) {
        const stackParts = errorObj.stack && errorObj.stack.split(/\u000A/);
        Object.assign(logObj, {
          level: 'ERROR',
          title: 'Exception ' + errorObj.message,
        });
        if (stackParts) {
          Object.assign(logObj, {
            description: stackParts[0],
            location: stackParts[1],
          });
        }
        logObj.name = errorObj.name;
        logObj.message = errorObj.message;
      }
    } else if (errorObj instanceof HttpErrorResponse) {
      // FIXME
      // if (pwaService.onlineStatus === EBitfOnlineStates.OFFLINE) {
      //   return;
      // }
      // NOTE do not log the error logger POST calls
      const isLoggerUrl = (errorObj.url || '').startsWith(environment.loggerConfig.logUrl);
      const statusCode = String(errorObj.status);
      const statusCodes = [statusCode, `${statusCode.split('')[0]}XX`];
      const shouldLogError =
        environment.loggerConfig.logHttpErrorsWithStatusesCodes.includes(statusCodes[0]) ||
        environment.loggerConfig.logHttpErrorsWithStatusesCodes.includes(statusCodes[1]);
      if (!isLoggerUrl && shouldLogError) {
        let errorTitle = 'HttpErrorResponse';
        const level = bitfGetProp(errorObj, 'error', 'metadata', 'uiMessages') ? 'WARN' : 'ERROR';
        if (errorObj.error && errorObj.error.name) {
          errorTitle = errorObj.error.name;
          logObj.name = errorObj.name;
        }
        Object.assign(logObj, {
          level,
          title: errorTitle + ' - ' + errorObj.message,
          httpErrorResponse: this.createHttpErrorLog(errorObj),
        });
      }
    } else {
      Object.assign(logObj, {
        level: (loggerParams && loggerParams.level) || 'INFO',
        title: loggerParams.title || 'Generic log',
        description: loggerParams.description || 'Generic log',
      });
    }
    if (Object.keys(logObj).length) {
      Object.assign(logObj, loggerParams);
      if (environment.loggerConfig.enabled) {
        setTimeout(() => {
          const loggerService = this.injector.get(BitfLoggerService);
          loggerService.log(logObj);
        }, 0);
      }
    }
  }

  private createHttpErrorLog(errorObj: HttpErrorResponse) {
    let errorString;
    try {
      errorString = JSON.stringify(errorObj.error);
    } catch (err) {
      errorString = undefined;
    }
    const { status, statusText, url, name, message } = errorObj;
    return {
      status,
      statusText,
      url,
      name,
      message,
      error: errorString,
      method: errorObj['method'],
      body:
        environment.loggerConfig.sendRequestBody && errorObj['requestBody']
          ? JSON.stringify(errorObj['requestBody'])
          : undefined,
      queryParams:
        environment.loggerConfig.sendQueryParams && errorObj['queryParams']
          ? JSON.stringify(errorObj['queryParams'])
          : undefined,
    };
  }
}

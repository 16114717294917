export class ConnectorStore {
  filters: {
    selectedConnector?: string;
    selectedCategory?: string;
    searchInput?: string;
    showActiveOnly?: boolean;
  };

  constructor(data: any) {
    Object.assign(this, data);
  }
}

import { IBitfApiCallState } from '@interfaces';
import { BitfApiCallStateMapper } from '@bitf/core/api-call-state/bitf-api-call-state.mapper';

export class DefaultApiCallStateMapper extends BitfApiCallStateMapper {
  buildApiRequest(apiCallState: IBitfApiCallState): void {
    apiCallState.apiRequest.queryParams = {};
    this.computedApiRequestParts.queryParams.forEach(query => {
      Object.assign(apiCallState.apiRequest.queryParams, query);
    });

    apiCallState.apiRequest.query = [];
    if (this.computedApiRequestParts.query.length) {
      this.computedApiRequestParts.query.forEach(queryParts => {
        apiCallState.apiRequest.query.push(...queryParts);
      });
    }
    apiCallState.apiRequest.sorting = [];
    if (this.computedApiRequestParts.sorting) {
      apiCallState.apiRequest.sorting = this.computedApiRequestParts.sorting;
    }
  }
}

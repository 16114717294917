import { SuperModel } from './super-model.model';

export class SubscriptionPlan extends SuperModel {
  name: string;
  price: number;
  users: number;
  projects: number;
  position: number;

  constructor(data = {}) {
    super(data);
  }

  get serialised() {
    return {};
  }
}

<div class="hero-upload-preview-container">
  <div class="hero-upload-preview-container__thumbnail-container">
    <ng-container *ngFor="let file of files; let i = index; trackBy: trackByFn">
      <div *ngIf="file.url"
        class="position-relative">
        <img [src]="file.url"
          class="hero-upload-thumbnail"
          alt="">
        <div class="close-icon-container"
          (click)="onRemoveFile(i)">
          <rmx-icon name="close-line"
            class="close-icon"></rmx-icon>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- <div class="progress">
    <div class="progress-bar"
      role="progress-bar"
      style="width: 63%"
      aria-valuenow="100"
      aria-valuemin="0"
      aria-valuemax="100"></div>
  </div>
  <p class="hero-upload-preview-container__upload">63% uploading...</p> -->
</div>
import { Injectable, Injector } from '@angular/core';

import { EUserRole } from '@common/enums';
import { RoleGuard } from '@common/services/guards';

@Injectable({
  providedIn: 'root',
})
export class CustomerGuard extends RoleGuard {
  permittedRole: EUserRole = EUserRole.CUSTOMER;

  constructor(protected injector: Injector) {
    super(injector);
  }
}

import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { ApiCallStateService } from '@services';
import { EApiRequestPartKeys, EApiCallStateNames } from '@enums';
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @Input() apiCallStateName: EApiCallStateNames;
  searchPart: BitfApiRequestPart;
  paginationPart: BitfApiRequestPart;
  searchFormControl: FormControl;
  private subscription: Subscription = new Subscription();

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    this.searchPart = this.apiCallStateService.getRequestPart(
      EApiCallStateNames.DEMO,
      EApiRequestPartKeys.SEARCH
    );
    this.paginationPart = this.apiCallStateService.getRequestPart(
      EApiCallStateNames.DEMO,
      EApiRequestPartKeys.PAGINATION
    );

    const initialValue = this.searchPart.data.searchKey;
    this.searchFormControl = new FormControl(initialValue);
    this.subscription.add(
      this.searchFormControl.valueChanges
        .pipe(debounceTime(300), distinctUntilChanged())
        .subscribe(value => this.updateStore(value))
    );
  }

  cancelSearch() {
    this.searchFormControl.setValue('', { emitEvent: false });
    this.updateStore('');
  }

  private updateStore(value: string) {
    this.apiCallStateService.setStore(() => {
      this.searchPart.data.searchKey = value;
      this.paginationPart.data.page = 1;
    }, this.apiCallStateName);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}

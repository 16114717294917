<div class="hero-account-card">
  <div class="hero-account-card-row">
    <div class="hero-account-card__user">
      <span class="hero-account-card__user--name">{{ user.firstName }} {{ user.lastName }}</span>
      <span class="hero-account-card__user--role">{{ 'COMMON.USER_SCOPE.' + user.scope | translate }}</span>
    </div>
    <hero-table-menu *ngIf="isOwner && ownerId != user.id"
      className="hero-table-menu-small"
      [actions]="actions"
      (optionClick)="onAction($event)"></hero-table-menu>
  </div>
  <div class="hero-account-card-row">
    <div class="hero-account-card__status">
      Active
    </div>
  </div>
</div>
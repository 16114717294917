<div class="hero-panel-legends">
  <ul class="hero-panel-legends-list">
    <li class="hero-panel-legends-item"
      [ngClass]="className"
      *ngFor="let item of items; trackBy: trackByFn">
      <span class="hero-panel-legends-item--color"
        [style.background-color]="item.color"></span>
      {{ item.label | translate }}
    </li>
  </ul>
</div>
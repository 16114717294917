import { HttpHeaders } from '@angular/common/http';

import { IBitfApiRequest, IBitfRequestMapper } from '@interfaces';
import { BITF_PARSER_HEADER_NAME } from '@constants';
import { EBitfParsers } from '@enums';
import justClone from 'just-clone';

export abstract class BitfRestRequestMapper implements IBitfRequestMapper {
  name = EBitfParsers.BITF_REST_PARSER;

  constructor() {}
  map(apiRequest: IBitfApiRequest) {
    const { body, ...requestParams } = apiRequest;
    // NOTE: We need to clone only request params, not the body here.
    const clonedRequestParams = justClone(requestParams);

    const {
      queryParams,
      file,
      files,
      bodyParser,
      responseType = 'json',
      reportProgress = false,
      skipInterceptors,
    } = clonedRequestParams;

    let { headers } = clonedRequestParams;

    const options: any = {
      responseType,
      headers: new HttpHeaders(),
      withCredentials: false,
      observe: 'response',
    };

    if (queryParams) {
      options.params = queryParams;
    }

    headers = headers || [];
    if (bodyParser === 'formUrlEncoded') {
      headers.push({
        headerName: 'Content-Type',
        value: 'application/form-urlencoded',
      });
    }

    if (headers && headers.length) {
      headers.forEach(header => {
        const { headerName, value } = header;
        options.headers = options.headers.append(headerName, value);
      });
    }

    options.reportProgress = reportProgress;

    if (file || files) {
      options.headers.append('Content-Type', 'multipart/form-data');
      Object.assign(options, {
        reportProgress: true,
        observe: 'events',
      });
    }

    if (skipInterceptors && skipInterceptors.length) {
      skipInterceptors.forEach(skipInterceptor => {
        options.headers = options.headers.append(skipInterceptor, '');
      });
    }

    // NOTE: this is needed in the
    options.headers = options.headers.append(BITF_PARSER_HEADER_NAME, this.name);

    return options;
  }
}

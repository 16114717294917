import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'heroNumber',
})
export class HeroNumberPipe implements PipeTransform {
  constructor() {}

  transform(value: number): string {
    const decimalPipe = new DecimalPipe('en');
    if (value < 99) {
      return decimalPipe.transform(value, '1.2-2');
    } else {
      return decimalPipe.transform(value, '1.0-0').replace(',', ' ');
    }
  }
}

import { EChannelName } from '@enums';

const channelsImagesMap = {
  [EChannelName.ADROLL]: 'adroll.svg',
  [EChannelName.CRITEO]: 'criteo.svg',
  [EChannelName.FACEBOOK]: 'facebook.svg',
  [EChannelName.GOOGLE_ADS]: 'google_ads.svg',
  [EChannelName.GOOGLE_ANALYTICS]: 'google_analytics.svg',
  [EChannelName.MS_BING]: 'ms_bing.svg',
  [EChannelName.SHOPIFY]: 'shopify.svg',
};

export function getImageSrcByChannel(channel: string) {
  return channelsImagesMap[channel];
}

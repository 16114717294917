import { Component, Injector, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { EBitfCloseEventStatus, EBitfUiMessageType } from '@enums';
import { IBitfDialogRef } from '@interfaces';
import { ToastMessagesService } from '@services';

@Component({
  selector: 'hero-base-modal',
  template: '',
})
export abstract class ModalBaseComponent implements OnInit {
  bitfDialogRef: IBitfDialogRef;
  toastMessagesService: ToastMessagesService;
  translationsService: TranslateService;
  scrollbarConfig = {};
  translations: any;
  eBitfCloseEventStatus = EBitfCloseEventStatus;
  eBitfUiMessageType = EBitfUiMessageType;

  abstract toTranslate: Array<string>;

  constructor(public injector: Injector) {
    this.toastMessagesService = this.injector.get<ToastMessagesService>(ToastMessagesService);
    this.translationsService = this.injector.get<TranslateService>(TranslateService);
  }

  ngOnInit() {
    if (this.toTranslate.length) {
      this.translationsService.get(this.toTranslate).subscribe(translations => {
        this.translations = translations;
      });
    }
  }

  showSuccess(toastMessage: string) {
    this.toastMessagesService.show({
      message: this.translations[toastMessage],
      title: 'success',
      type: EBitfUiMessageType.SUCCESS,
    });
  }

  showWarning(toastMessage: string) {
    this.toastMessagesService.show({
      message: this.translations[toastMessage],
      title: 'warning',
      type: EBitfUiMessageType.WARNING,
    });
  }

  showError(toastMessage: string) {
    this.toastMessagesService.show({
      message: this.translations[toastMessage],
      title: 'error',
      type: EBitfUiMessageType.ERROR,
    });
  }

  close() {
    this.bitfDialogRef.close({ status: EBitfCloseEventStatus.CLOSE, data: {} });
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials',
})
export class UserInitialsPipe implements PipeTransform {
  transform(value: string): unknown {
    return value
      .split(' ')
      .map(v => v[0])
      .join('');
  }
}

<div class="d-flex">
  <bitf-dnd-file (dropFiles)="onFileDropped($event)"
    *ngIf="showDndArea"
    class="hero-avatar-uploader">
    <button *ngIf="files.length !== 1"
      class="btn btn-outline btn-outline--focused hero-avatar-uploader__choose-button"
      (click)="$event.preventDefault(); selectFile.click()">
      {{ 'COMMON.AVATAR_FILE_UPLOADER.BUTTON.UPLOAD_LOGO' | translate }}
    </button>
    <img *ngIf="files.length == 1"
      [src]="files[0].url"
      class="hero-avatar-uploader__img"
      alt="">
    <input class="d-none"
      type="file"
      #selectFile
      [attr.multiple]="(maxNumberOfFiles > 1) ? '' : null"
      (change)="onFileDropped($event)" />
  </bitf-dnd-file>
  <button class="btn-edit-icon hero-avatar-uploader__remove-btn"
    *ngIf="files.length == 1"
    (click)="removeFile(0)">
    <rmx-icon name="delete-bin-4-line"
      class="delete-bin-4-line"></rmx-icon>
  </button>
</div>
<ng-content class="hero-avatar-uploader__note"></ng-content>
<div class="drv-card-blank"
  [ngClass]="className">
  <h3 class="drv-card-blank__title">{{ 'CAMPAIGNS.CART.NEW_CAMPAIGN_TITLE' | translate }}</h3>
  <p class="drv-card-blank__paragraph">
    {{ 'CAMPAIGNS.CART.NEW_CAMPAIGN_PARAGRAPH' | translate }}
  </p>
  <button [routerLink]="link"
    class="btn btn-primary">
    {{ 'CAMPAIGNS.CART.NEW_CAMPAIGN_BUTTON' | translate }}
  </button>
</div>
import { Component, Input, OnInit } from '@angular/core';

import { IHeroPanelLegend } from '@common/interfaces';

@Component({
  selector: 'hero-panel-legends',
  templateUrl: './panel-legends.component.html',
  styleUrls: ['./panel-legends.component.scss'],
})
export class PanelLegendsComponent implements OnInit {
  @Input() className = '';
  @Input() items: IHeroPanelLegend[];

  constructor() {}

  ngOnInit(): void {}

  trackByFn(index) {
    return index;
  }
}

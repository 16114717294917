import { ChartConfiguration, ChartDataset } from 'chart.js';

import { EColorsRGBA } from '@common/enums';

const lineChartConfig: ChartConfiguration['options'] = {
  font: {
    family: 'Soleil',
  },
  // devicePixelRatio: 1,
  maintainAspectRatio: true,
  responsive: true,
  aspectRatio: 8 / 3,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      mode: 'index',
      intersect: false,
      titleFont: {
        family: 'Soleil',
        size: 13,
      },
      bodyFont: {
        family: 'Soleil',
        size: 13,
      },
      bodySpacing: 4,
    },
  },
  hover: {
    mode: 'nearest',
    intersect: true,
  },
  scales: {
    xAxes: {
      display: true,
      grid: { color: 'rgba(249, 249, 251, 1)', drawOnChartArea: true, drawTicks: false, drawBorder: false },
      offset: false,
      ticks: {
        font: { family: 'Soleil', size: 12, weight: 'regular' },
        color: 'rgba(176, 176, 176, 1)',
        padding: 20,
      },
    },
    yAxes: {
      display: true,
      grid: { color: 'rgba(249, 249, 251, 1)', drawOnChartArea: true, drawTicks: false, drawBorder: false },
      ticks: {
        font: { family: 'Soleil', size: 12, weight: 'regular' },
        color: 'rgba(176, 176, 176, 1)',
        padding: 20,
      },
      title: {
        display: false,
      },
    },
  },
};

export const chartDefaultConfig: { [key: string]: ChartConfiguration['options'] } = {
  line: lineChartConfig,
};

export const defaultLineDatasetConfig: Partial<ChartDataset<'line'>> = {
  fill: false,
  pointRadius: 0,
  borderCapStyle: 'round',
  borderJoinStyle: 'round',
  tension: 0.3,
};

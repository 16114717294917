import { HttpHeaders } from '@angular/common/http';

import justClone from 'just-clone';

import { BitfRestRequestMapper } from '@bitf/core/parsers/rest-parser';

import { IBitfApiRequest } from '@interfaces';
import { BITF_PARSER_HEADER_NAME } from '@constants';

export class AppRequestMapper extends BitfRestRequestMapper {
  constructor() {
    super();
  }

  map(apiRequest: IBitfApiRequest) {
    const { body, ...requestParams } = apiRequest;
    // NOTE: We need to clone only request params, not the body here.
    const clonedRequestParams = justClone(requestParams);
    const {
      queryParams,
      file,
      files,
      bodyParser,
      responseType = 'json',
      reportProgress = false,
      skipInterceptors,
      query,
      page,
      size,
      sorting,
    } = clonedRequestParams;

    let { headers } = clonedRequestParams;

    const options: any = {
      responseType,
      headers: new HttpHeaders(),
      withCredentials: false,
      observe: 'response',
    };

    if (queryParams) {
      options.params = queryParams;
    }

    if (query) {
      options.params.query = JSON.stringify(query);
    }

    if (page && size) {
      options.params.page = page;
      options.params.size = size;
    }

    if (sorting && sorting.length) {
      const apiSort = {};
      sorting.forEach(sortingItem => {
        apiSort[sortingItem[0].property] = sortingItem[0].direction;
      });
      options.params.sort = JSON.stringify(apiSort);
      console.log('apiSort', apiSort);
    }

    headers = headers || [];
    if (bodyParser === 'formUrlEncoded') {
      headers.push({
        headerName: 'Content-Type',
        value: 'application/form-urlencoded',
      });
    }

    if (headers && headers.length) {
      headers.forEach(header => {
        const { headerName, value } = header;
        options.headers = options.headers.append(headerName, value);
      });
    }

    options.reportProgress = reportProgress;

    if (file || files) {
      options.headers.append('Content-Type', 'multipart/form-data');
      Object.assign(options, {
        reportProgress: true,
        observe: 'events',
      });
    }

    if (skipInterceptors && skipInterceptors.length) {
      skipInterceptors.forEach(skipInterceptor => {
        options.headers = options.headers.append(skipInterceptor, '');
      });
    }

    // NOTE: this is needed in the
    options.headers = options.headers.append(BITF_PARSER_HEADER_NAME, this.name);

    return options;
  }
}

import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISearchable, ISearchResultSet } from '../../interfaces/search.interface';

export abstract class BaseSearchService {
  abstract services: ISearchable[];

  search(keyword: string): Observable<ISearchResultSet[]> {
    return forkJoin(this.services.map(service => service.search(keyword))).pipe(
      map(responses => {
        const searchResultsSets: ISearchResultSet[] = [];
        responses.forEach(searchResultSet => {
          searchResultsSets.push(searchResultSet);
        });
        return searchResultsSets;
      })
    );
  }
}

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({ selector: '[heroNotEmpty]' })
export class HeroNotEmptyDirective implements OnInit {
  @Input('heroNotEmpty') set data(data: any) {
    this._isEmpty = !(!data || (Array.isArray(data) && !data.length));

    this.adjustVisibility();
  }

  _isEmpty = true;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  ngOnInit() {
    this.adjustVisibility();
  }

  private adjustVisibility() {
    if (this._isEmpty) {
      this.hideElement();
    } else {
      this.showElement();
    }
  }

  private hideElement() {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private showElement() {
    this.viewContainer.clear();
  }
}

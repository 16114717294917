import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { EBitfUiMessageType, EUserRole } from '@enums';

import { BitfAuthGuard } from '@bitf/core/services/guards/bitf-auth.guard';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { ToastMessagesService } from '@web/core/services';

export abstract class RoleGuard extends BitfAuthGuard {
  abstract permittedRole: EUserRole;

  toastMessagesService: ToastMessagesService;
  translateService: TranslateService;

  toTranslate: Array<string> = [
    bitfToTranslate('COMMON.TOAST_MESSAGES.ERROR'),
    bitfToTranslate('COMMON.AUTH.FORBIDDEN'),
  ];

  constructor(protected injector: Injector) {
    super(injector);
    this.toastMessagesService = this.injector.get<ToastMessagesService>(ToastMessagesService);
    this.translateService = this.injector.get<TranslateService>(TranslateService);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const user = this.storeService.store.user;

    if (!user || user.role !== this.permittedRole) {
      this.showError();
      this.authService.signOut();
      return false;
    }
    return true;
  }

  showError() {
    this.translateService.get(this.toTranslate).subscribe(translations => {
      this.toastMessagesService.show({
        type: EBitfUiMessageType.ERROR,
        title: translations['COMMON.TOAST_MESSAGES.ERROR'],
        message: translations['COMMON.AUTH.FORBIDDEN'],
      });
    });
  }
}

import { HttpRequest } from '@angular/common/http';
export const bitfIsCallingApi = (environment, req: HttpRequest<any>) => {
  return (
    environment.authInterceptorUrls.some(url => req.url.includes(url)) ||
    req.url.includes('localhost') ||
    req.url.includes(environment.mockApiUrl)
  );
};
export const bitfIsCallingMockService = (environment, req: HttpRequest<any>) => {
  return req.url.includes(environment.mockApiUrl);
};

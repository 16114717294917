import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { EApiQueryPartOperator, EApiRequestPartKeys, EChannelName } from '@enums';
import { EBitfApiRequestPartsNames } from '@enums';

export class CampaignStatusApiCallStatePart extends BitfApiRequestPart {
  constructor(defaultStatus = 'all') {
    super(EApiRequestPartKeys.CAMPAIGNS_STATUS, EBitfApiRequestPartsNames.QUERY, {
      formValue: {
        status: defaultStatus,
      },
    });
  }

  build(): void {
    this.part = undefined;
    if (!this.formValue || !this.formValue.status || this.formValue.status.toUpperCase() === 'ALL') {
      return;
    }
    this.part = [
      {
        operator: EApiQueryPartOperator.EQ,
        property: 'status',
        value: this.formValue.status,
      },
    ];
  }
}

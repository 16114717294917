import { Component, Input, OnInit } from '@angular/core';

import { StoreService } from '@common/core/services';

import { IMenuItem } from '@web/interfaces';

import { EUserRole } from '@enums';

@Component({
  selector: 'hero-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() className: string = null;
  @Input() menuItems: IMenuItem[] = [];

  eUserRole = EUserRole;

  get user() {
    return this.storeService.store.user;
  }

  constructor(public storeService: StoreService) {}

  ngOnInit(): void {}
}

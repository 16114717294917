export enum EBitfAuthState {
  TO_BE_LOGGED = 'TO_BE_LOGGED',
  LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
  TOKEN_RETRIEVED = 'TOKEN_RETRIEVED',
  LOGGED = 'LOGGED',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_INFO = 'LOGIN_INFO',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  NOT_AUTHORISED = 'NOT_AUTHORISED',
}

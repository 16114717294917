import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { IHeroDropdownItem } from '@interfaces';

@Component({
  selector: 'hero-table-menu',
  templateUrl: './table-menu.component.html',
  styleUrls: ['./table-menu.component.scss'],
})
export class TableMenuComponent implements OnInit {
  @Output() optionClick = new EventEmitter<IHeroDropdownItem>();
  @Input() className: string = null;
  @Input() actions: IHeroDropdownItem[] = [
    {
      label: bitfToTranslate('TABLE_MENU.LABEL.PREVIEW'),
      value: bitfToTranslate('TABLE_MENU.LABEL.PREVIEW'),
      url: '/demo',
      disabled: true,
    },
    {
      label: bitfToTranslate('TABLE_MENU.LABEL.INSIGHTS'),
      value: bitfToTranslate('TABLE_MENU.LABEL.INSIGHTS'),
      url: '/demo',
    },
    {
      label: bitfToTranslate('TABLE_MENU.LABEL.REVIEW'),
      value: bitfToTranslate('TABLE_MENU.LABEL.REVIEW'),
      url: '/demo',
      disabled: true,
    },
  ];

  isListVisible = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onActionsClick() {
    this.isListVisible = !this.isListVisible;
  }

  onActionSelect(item: IHeroDropdownItem) {
    this.isListVisible = false;
    this.optionClick.emit(item);
    if (item.url) {
      this.router.navigateByUrl(item.url);
    }
  }

  trackByFn(index) {
    return index;
  }
}

import { IBitfBrowser } from '@interfaces';
import { EBitfBrowser } from '@enums';

let cachedBrowser: IBitfBrowser;

export function detectBrowser(): IBitfBrowser {
  const browser: IBitfBrowser = {
    isOpera: false,
    isFirefox: false,
    isSafari: false,
    isSafariMobile: false,
    isIe: false,
    isEdge: false,
    isChrome: false,
    isBlink: false,
    currentBrowser: EBitfBrowser.NOT_DEFINED,
    isApple: false,
  };

  if (cachedBrowser) {
    return cachedBrowser;
  }

  // Opera 8.0+
  browser.isOpera =
    (!!window['opr'] && !!window['opr'].addons) ||
    !!window['opera'] ||
    navigator.userAgent.indexOf(' OPR/') >= 0;

  if (browser.isOpera) {
    browser.currentBrowser = EBitfBrowser.OPERA;
  }

  // Firefox 1.0+
  // @ts-ignore
  browser.isFirefox = typeof InstallTrigger !== 'undefined';
  if (browser.isFirefox) {
    browser.currentBrowser = EBitfBrowser.FIREFOX;
  }

  // Safari
  browser.isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  if (browser.isSafari) {
    browser.currentBrowser = EBitfBrowser.SAFARI;
  }

  browser.isSafariMobile =
    !!window.navigator.userAgent.match(/iPad/i) || !!window.navigator.userAgent.match(/iPhone/i);
  if (browser.isSafariMobile) {
    browser.currentBrowser = EBitfBrowser.SAFARI_MOBILE;
  }

  browser.isApple = browser.isSafari && browser.isSafariMobile;

  // Internet Explorer 6-11
  browser.isIe = /*@cc_on!@*/ false || !!document['documentMode'];
  if (browser.isIe) {
    browser.currentBrowser = EBitfBrowser.IE;
  }

  // Edge 20+
  browser.isEdge = !browser.isIe && !!window['StyleMedia'];
  if (browser.isEdge) {
    browser.currentBrowser = EBitfBrowser.EDGE;
  }

  const isChromium = !!window['chrome'];
  const vendorName = window.navigator.vendor;
  // is Google Chrome on IOS
  const isIOSChrome = window.navigator.userAgent.match('CriOS');

  if (isIOSChrome || (isChromium && vendorName === 'Google Inc.' && !browser.isOpera && !browser.isEdge)) {
    browser.isChrome = true;
  }
  if (browser.isChrome) {
    browser.currentBrowser = EBitfBrowser.CHROME;
  }

  // Blink engine detection
  browser.isBlink = (browser.isChrome || browser.isOpera) && !!window['CSS'];

  cachedBrowser = browser;
  return browser;
}

import { Component, Input, OnInit } from '@angular/core';

import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IBitfApiPagination } from '@interfaces';
import { ApiCallStateService } from '@services';

@Component({
  selector: 'hero-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  @Input() set pagination(pagination: IBitfApiPagination) {
    this.processPagination(pagination);
  }
  @Input() apiCallStateName: EApiCallStateNames;

  numberOfPagesOptions = 5;
  totalPages: number;
  currentPage: number;
  pages: number[];
  prevPage: number;
  nextPage: number;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {}

  changePage(page: number) {
    if (!page || page === this.currentPage) {
      return;
    }
    const apiCallStatePart = this.getApiCallStatePart();
    apiCallStatePart.data = {
      ...apiCallStatePart.data,
      page,
    };
    this.apiCallStateService.setStore(store => store, this.apiCallStateName);
  }

  private getApiCallStatePart() {
    return this.apiCallStateService.getRequestPart(this.apiCallStateName, EApiRequestPartKeys.PAGINATION);
  }

  private processPagination(pagination: IBitfApiPagination) {
    if (!pagination) {
      return;
    }

    this.currentPage = pagination.page;
    this.totalPages = pagination.totalPages;
    this.pages = [];

    if (this.currentPage > 1) {
      this.prevPage = this.currentPage - 1;
    } else {
      this.prevPage = 0;
    }

    if (this.currentPage + 1 <= pagination.totalPages) {
      this.nextPage = this.currentPage + 1;
    } else {
      this.nextPage = 0;
    }
    this.fillPages();
  }

  private fillPages() {
    this.pages = [];
    let totalPagesToFill = this.numberOfPagesOptions - 1;

    const availablePrevPages = this.countHowManyPrevPages(this.currentPage);
    const availableNextPages = this.countHowManyNextPages(this.currentPage, this.totalPages);

    const numberOfPagesPerSide = (this.numberOfPagesOptions - 1) / 2;

    let pagesMinIndex = 0;
    if (availablePrevPages >= numberOfPagesPerSide) {
      pagesMinIndex = this.currentPage - numberOfPagesPerSide;
      totalPagesToFill -= numberOfPagesPerSide;
    } else {
      totalPagesToFill -= this.currentPage - 1;
      pagesMinIndex = 1;
    }

    let pagesMaxIndex = pagesMinIndex;
    if (availableNextPages >= totalPagesToFill) {
      pagesMaxIndex = this.currentPage + totalPagesToFill;
      totalPagesToFill = 0;
    } else {
      pagesMaxIndex = this.totalPages;
      totalPagesToFill -= availableNextPages;
    }

    if (totalPagesToFill && pagesMinIndex > 1) {
      if (pagesMinIndex - totalPagesToFill >= 1) {
        pagesMinIndex -= totalPagesToFill;
      } else {
        pagesMinIndex = 1;
      }
    }

    for (let page = pagesMinIndex; page <= pagesMaxIndex; page++) {
      this.pages.push(page);
    }
  }

  private countHowManyPrevPages(currentPage: number) {
    return currentPage - 1;
  }

  private countHowManyNextPages(currentPage: number, totalPages: number) {
    return totalPages - currentPage;
  }
}

import { Component, Input, OnInit, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { IHeroRadio, IHeroRadioBox } from '@common/interfaces';

const CUSTOM_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RadioBoxComponent),
  multi: true,
};

@Component({
  selector: 'hero-radio-box',
  templateUrl: './radio-box.component.html',
  styleUrls: ['./radio-box.component.scss'],
  providers: [CUSTOM_CONTROL_VALUE_ACCESSOR],
})
export class RadioBoxComponent implements OnInit, ControlValueAccessor {
  @Input() className: string;
  @Input() item: IHeroRadioBox;
  @Output() radioSelect = new EventEmitter<IHeroRadio>();

  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (arg: any) => void = () => {};

  constructor() {}

  ngOnInit(): void {}

  trackByFn(index) {
    return index;
  }

  onSelect(item) {
    this.radioSelect.emit(item);
    this.onChangeCallback(item);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(item: IHeroRadioBox) {
    // TOFIX
    if (!this.item.value) {
      return;
    }
    this.onChangeCallback(this.item.value);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}

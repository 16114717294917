import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError, TimeoutError, timer } from 'rxjs';
import { timeout, retryWhen, switchMap } from 'rxjs/operators';

import { bitfIsCallingApi } from '@bitf/utils/bitf-urls.utils';

import { environment } from '@env/environment';
import { EBitfInterceptors } from '@enums';

@Injectable()
export class BitfRetryInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!bitfIsCallingApi(environment, req) || !environment.httpRetryConfig.enabled) {
      return next.handle(req);
    }

    if (req.headers.has(EBitfInterceptors.BITF_RETRY_INTERCEPTOR)) {
      const newReq = req.clone({
        headers: req.headers.delete(EBitfInterceptors.BITF_RETRY_INTERCEPTOR),
      });
      return next.handle(newReq);
    }

    const config = environment.httpRetryConfig.verbs[req.method.toLowerCase()] || null;
    if (!config || !config.enabled) {
      return next.handle(req);
    }

    let retryAttempt = 0;
    return next.handle(req).pipe(
      timeout(config.timeout * (1 + 0.2 * retryAttempt)),
      retryWhen(
        (obs: Observable<Error>): Observable<any> => {
          return obs.pipe(
            switchMap(error => {
              retryAttempt++;
              const shouldRetryForStatus =
                error instanceof HttpErrorResponse && config.retryForStatusesCodes.includes(error.status);
              const shouldRetryAgain = retryAttempt <= config.maxRetries;
              const isTimeoutError = error instanceof TimeoutError;
              if (isTimeoutError) {
                // Create an HttpErrorResponse to send data to logger
                error = new HttpErrorResponse({ url: req.url, headers: req.headers, error });
              }
              const shouldRetryOnTimeout = isTimeoutError && config.timeout;
              if (shouldRetryAgain && (shouldRetryForStatus || shouldRetryOnTimeout)) {
                // NOTE: wait 1sec + incremental shift before to do another call
                return timer(1000 * (1 + 0.2 * (retryAttempt - 1)));
              }
              return throwError(error);
            })
          );
        }
      )
    );
  }
}

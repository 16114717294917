import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { StoreService } from '@services';

import { EInsightCardType } from '@enums';
import { IDrvConnectionItem } from '@interfaces';

@Component({
  selector: 'hero-insights-pointer',
  templateUrl: './insights-pointer.component.html',
  styleUrls: ['./insights-pointer.component.scss'],
})
export class InsightsPointerComponent implements OnInit {
  @ViewChild('insightPointer', { static: true }) insightPointer: ElementRef;

  @Output() pointerClick = new EventEmitter<IDrvConnectionItem>();

  @Input() positionClass = 'top left';
  @Input() type: EInsightCardType;

  get insights() {
    return this.storeService.store.insights;
  }

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {}

  restartPointerAnimation() {
    this.insightPointer.nativeElement.classList.remove('pulse');
    setTimeout(() => {
      this.insightPointer.nativeElement.classList.add('pulse');
    }, 50);
  }

  onPointerClick() {
    this.pointerClick.emit();
  }

  trackByFn(index) {
    return index;
  }
}

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { EApiRequestPartKeys, ETimeUnit } from '@enums';
import { EBitfApiRequestPartsNames } from '@enums';

export class TimeUnitApiRequestPart extends BitfApiRequestPart {
  constructor(defaultTimeUnit: ETimeUnit = ETimeUnit.DAY) {
    super(EApiRequestPartKeys.TIME_UNIT_FILTER, EBitfApiRequestPartsNames.QUERY_PARAMS, {
      formValue: {
        timeUnit: defaultTimeUnit,
      },
    });
  }

  build(): void {
    this.part = undefined;
    if (!this.formValue || !this.formValue.timeUnit) {
      return;
    }
    this.part = this.formValue;
  }
}

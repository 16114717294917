import { EChannelName, EStatisticsParam, ESummaryCardType } from '@common/enums';
import { SuperModel } from './super-model.model';

export class StatisticsParam extends SuperModel {
  label: string;
  key: EStatisticsParam;
  dataType: ESummaryCardType;
  negativeIsExpected: boolean;
  channelsStatistics: IChannelStatistics[];

  get serialised() {
    return {};
  }
}

export interface IChannelStatistics {
  channel: EChannelName;
  statistics: IStatisticValue;
}

export interface IStatisticValue {
  value?: number;
  previousPeriodValue?: number;
  valueChange?: number;
  disabled?: boolean;
  error?: string;
}

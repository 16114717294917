import { Component, Input, OnInit } from '@angular/core';

import { IMenuItem } from '@web/interfaces';

@Component({
  selector: 'hero-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
})
export class SidebarMenuComponent implements OnInit {
  @Input() menuItems: IMenuItem[] = [];

  constructor() {}

  ngOnInit(): void {}

  trackByFn(index: number): number {
    return index;
  }
}

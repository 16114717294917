import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { EInsightCardType } from '@enums';
import { IDrvConnectionItem } from '@interfaces';
import { StoreService } from '@services';

@Component({
  selector: 'hero-insight-card',
  templateUrl: './insight-card.component.html',
  styleUrls: ['./insight-card.component.scss'],
})
export class InsightCardComponent implements OnInit {
  @Input() createdAt: Date;
  @Input() title: string;
  @Input() type: EInsightCardType;
  @Input() description: string;
  @Input() buttonText: string;
  @Output() closeClick = new EventEmitter<IDrvConnectionItem>();
  @Output() actionClick = new EventEmitter<IDrvConnectionItem>();
  @Output() selectCard = new EventEmitter<IDrvConnectionItem>();

  get insights() {
    return this.storeService.store.insights;
  }

  constructor(private storeService: StoreService) {}

  ngOnInit(): void {}

  onCloseClick($event: MouseEvent) {
    $event.stopPropagation();
    this.closeClick.emit();
  }

  onActionClick() {
    this.actionClick.emit();
  }

  onSelectCard() {
    this.selectCard.emit();
  }

  trackByFn(index) {
    return index;
  }
}

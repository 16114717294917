import { Subject } from 'rxjs';
import extend from 'just-extend';
import justClone from 'just-clone';

import { environment } from '@env/environment';
import { CONSTANTS } from '@constants';
import { IBitfStorage } from './bitf-storage.interface';

/**
 * BitfStorageService
 * Service to interact with a native Storage (like localStorage) that supports versioning
 */
export abstract class BitfStorageService<T extends any> {
  storage$ = new Subject<T>();
  protected storageKey = `${environment.appName}-storage`;
  protected nativeStorage: Storage;
  protected storageClass: new (data?: Partial<T>) => T;
  private initialData: Partial<T>;

  // NOTE: inital data must be an object to be passed in the storeClass constructor not a storeClass instance
  // this is to avoid to create something like new storeClass(justClone(StoreClassInstance)); which will
  // lead to problems

  /**
   *
   * @param initialData the initial data for the storage
   * @param storageClass the class of the Storage model
   * @param nativeStorage the native storage to be used (localStorage, sessionStorage or a storage like obj)
   * @parame storageKey the storage key to be used to persist the storage data inside the native storage
   */
  constructor({
    initialData,
    storageClass,
    nativeStorage = localStorage,
    storageKey,
  }: {
    initialData?: Partial<T>;
    storageClass?: new (data?: Partial<T>) => T;
    nativeStorage?: Storage;
    storageKey?: string;
  } = {}) {
    if (!storageClass) {
      throw new Error('storageClass is undefined');
    }
    this.storageClass = storageClass;
    this.nativeStorage = nativeStorage;
    this.initialData = initialData || ({} as T);
    if (storageKey) {
      this.storageKey = `${environment.appName}-${storageKey}`;
    }
    this.initStorage();
    this.validateStorage();
  }

  private initStorage() {
    if (!this.storage) {
      this.storage = this.getEmptyStorage();
    }
  }

  private getEmptyStorage() {
    return {
      createdAt: Date.now(),
      modifiedAt: Date.now(),
      version: CONSTANTS.LOCAL_STORAGE_VERSION,
      data: new this.storageClass(justClone(this.initialData)),
    };
  }

  /**
   * NOTE: consider this object as immutable, since every thime this getter runs you'll receive a new
   * object. This could be also a performance bottleneck in case we've tons of readings from the local storage
   *
   * @returns The storage row data
   */
  get storage(): IBitfStorage<T> {
    const storageData = JSON.parse(this.nativeStorage.getItem(this.storageKey));
    if (storageData && storageData.data) {
      return { ...storageData, data: new this.storageClass(storageData.data) };
    }
    return storageData;
  }

  /**
   * This setter will overwrite the storage data writing directly to the storage.
   * Use storage.data = ... instead
   */
  set storage(storage: IBitfStorage<T>) {
    this.nativeStorage.setItem(this.storageKey, JSON.stringify(storage));
  }

  /**
   * @returns The parsed storage Model
   */
  get data(): T {
    if (this.storage && this.storage.data) {
      return new this.storageClass(this.storage.data);
    }
    return new this.storageClass();
  }

  /**
   * This setter will update hte storage data (not overwrite)
   */
  set data(data: T) {
    const storage = this.storage;
    storage.data = extend(this.storage.data as any, data) as T;
    this.updateStorage(storage);
  }

  /**
   * This function will overwrite the storage model
   * @param data the Storage model
   */
  setData(data: T) {
    const storage = this.storage;
    storage.data = data;
    this.updateStorage(storage);
  }

  /**
   * This method will clear storage data
   */
  clearData() {
    const storage = this.storage;
    storage.data = {} as T;
    this.updateStorage(storage);
  }

  /**
   * This method will reset storage data
   */
  resetStorage() {
    const storage = this.migrateStorage();
    this.storage = storage;
    this.storage$.next(storage.data);
  }

  /**
   * Method to be override to create a custom migrate logic for the application specific storage
   */
  migrateStorage() {
    return this.getEmptyStorage();
  }

  private validateStorage() {
    const storage = this.storage;
    if (storage.version !== CONSTANTS.LOCAL_STORAGE_VERSION) {
      this.resetStorage();
    }
  }

  private updateStorage(storage: IBitfStorage<T>) {
    storage.modifiedAt = Date.now();
    this.storage = storage;
    this.storage$.next(storage.data);
  }
}

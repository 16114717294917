<div class="drv-insight-pointer-container">
  <div class="drv-insight-pointer pulse"
    [class.pointer-selected]="insights?.filters?.selectedType === type"
    [ngClass]="positionClass"
    #insightPointer
    (mouseenter)="restartPointerAnimation()"
    (mouseleave)="restartPointerAnimation()"
    (click)="onPointerClick()">
    <rmx-icon name="focus-3-line"></rmx-icon>
  </div>
  <ng-content></ng-content>
</div>

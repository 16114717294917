import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hero-text-with-copy',
  templateUrl: './text-with-copy.component.html',
  styleUrls: ['./text-with-copy.component.scss'],
})
export class TextWithCopyComponent implements OnInit {
  @Input() text = '';

  constructor() {}

  ngOnInit(): void {}
}

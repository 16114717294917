import { EInsightCardType } from '@common/enums';

export class InsightsStore {
  types: EInsightCardType[];
  filters?: {
    selectedType?: EInsightCardType;
    showInsights?: boolean;
  };

  constructor(data: any) {
    Object.assign(this, data);
  }
}

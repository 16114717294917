import { HttpResponse } from '@angular/common/http';
import { IBitfApiResponse } from '@interfaces';
import { modelsMap } from '@common/parsers/models-mapper/models-mapper.strategy';

export abstract class BitfRestResponseMapper {
  constructor() {}

  getEnvelope<T>(apiOutput: HttpResponse<IBitfApiResponse<T | T[]>>): IBitfApiResponse<T | T[]> {
    return apiOutput && apiOutput.body;
  }

  modelInstanceFactory<T>(item: any, modelMapper: string): T {
    const model = modelsMap.get(modelMapper);
    if (!model) {
      return item;
    }
    return new model(item);
  }

  modelInstancesFactory<T>(items: any, modelMapper: string): T[] {
    const model = modelsMap.get(modelMapper);
    if (!model) {
      return items;
    }
    return items.map(item => new model(item));
  }

  unwrapExtraModelFields(item: any) {
    return item;
  }

  isResponsePaged<T>(response: IBitfApiResponse<T[]>): boolean {
    return this.isResponseList(response) && !!response.pagination;
  }

  isResponseList<T>(response: IBitfApiResponse<T[]>): boolean {
    return Array.isArray(response.content);
  }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { MetadataService } from '@common/core/services';
import { IBitfApiResponse } from '@common/interfaces';

import { IMetadataOpenGraph } from '@web/interfaces';

@Component({
  selector: 'hero-preview-website-meta',
  templateUrl: './preview-website-meta.component.html',
  styleUrls: ['./preview-website-meta.component.scss'],
})
export class PreviewWebsiteMetaComponent implements OnInit, OnChanges {
  @Input() url: string;

  metadata: IMetadataOpenGraph = null;
  image;

  get imageUrl(): string {
    if (!this.metadata || !this.metadata.ogImage) {
      return null;
    }
    if (Array.isArray(this.metadata.ogImage)) {
      return this.metadata.ogImage[0].url;
    }
    return this.metadata.ogImage.url;
  }

  get urlWithoutProtocol(): string {
    return this.url.replace(/^(https|http)?:\/\//, '');
  }

  private loadImage() {
    const image = new Image();
    this.image = null;

    image.onload = () => {
      this.image = image;
    };

    image.src = this.imageUrl;
  }

  constructor(private metadataService: MetadataService) {}

  ngOnInit(): void {}

  ngOnChanges() {
    this.loadMetadata();
  }

  loadMetadata() {
    if (this.url) {
      this.metadataService
        .post({ isBodyRaw: true, body: { url: this.url }, path: '/search-og' })
        .subscribe((response: IBitfApiResponse<IMetadataOpenGraph>) => {
          this.metadata = response.content && Object.keys(response.content).length ? response.content : null;
          this.loadImage();
        });
    }
  }
}

import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';

import { LoaderComponent } from './loader/loader.component';

@Directive({ selector: '[heroLoader]' })
export class HeroLoaderDirective implements OnInit {
  @Input('heroLoader') set isActive(isActive: boolean) {
    this._isActive = isActive;

    if (!this.loaderComponent) {
      return;
    }

    if (isActive) {
      this.loaderComponent.instance.show();
    } else {
      this.loaderComponent.instance.hide();
    }
  }

  _isActive = false;
  loaderComponent: ComponentRef<LoaderComponent>;

  constructor(
    private templateRef: TemplateRef<void>,
    private vcr: ViewContainerRef,
    private cfr: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.vcr.createEmbeddedView(this.templateRef);
    const cmpFactory = this.cfr.resolveComponentFactory(LoaderComponent);
    this.loaderComponent = this.vcr.createComponent<LoaderComponent>(cmpFactory);
    if (this._isActive) {
      this.loaderComponent.instance.show();
    }
  }
}

import { SuperModel } from './super-model.model';

export class AdvAccount extends SuperModel {
  id: string;
  name: string;
  children?: Array<AdvAccount>;
  currency?: string;

  constructor(data: any) {
    super(data);
    if (data.children) {
      this.children = data.children.map(childAccount => new AdvAccount(childAccount));
    }
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      children: this.children.map(child => child.serialised),
    };
  }
}

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { BITF_VALIDATION_MESSAGES } from '@bitf/constants';
// tslint:disable-next-line:max-line-length
import { OkCancelDialogComponent } from '@common/shared/components/modals/ok-cancel-dialog/ok-cancel-dialog.component';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [{ code: 'en', label: 'English' }],
  DEFAULT_LANGUAGE: { code: 'en', label: 'English' },
  LOCAL_STORAGE_VERSION: 1,
  HERO_PASSWORD_REGEX: /^(?=(.*[A-Z]){2,})(?=(.*[\W]){1,})(?!.*\s).{3,}$/,
  HERO_URL_REGEX: /((?:http:\/\/)|(?:https:\/\/))(www.)?([\da-z.:-]+)\.([a-z.]{2,6})[/\w .-]*/,
  HERO_URL_WITHOUT_PROTOCOL_REGEX: /([\da-z.:-]+)\.([a-z.]{2,6})[/\w .-]*/,
  fullScreenMessageComponent: null,
  dateFormat: 'MMM dd, y',
  okCancelDialogComponent: OkCancelDialogComponent,
  VALIDATION_MESSAGES: {
    ...BITF_VALIDATION_MESSAGES,
    passwordValidator: bitfToTranslate('BITF.VALIDATION_ERROR.INVALID_PASSWORD'),
    urlValidator: bitfToTranslate('BITF.VALIDATION_ERROR.INVALID_URL'),
    conditionalValidator: bitfToTranslate('BITF.VALIDATION_ERROR.REQUIRED'),
    invalidMatchOther: bitfToTranslate('BITF.VALIDATION_ERROR.MATCH_OTHER'),
    invalidAgeSelection: bitfToTranslate('CAMPAIGN_STEP.TARGET.VALIDATION.INVALID_AGE_SELECTION'),
  },
  dataTableSizeOptions: [5, 10, 20, 100],
  toastMessagesDisplayingTime: 10000,
};

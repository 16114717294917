export enum ERoleMode {
  READ_ONLY = 'READ_ONLY',
  HIDDEN = 'HIDDEN',
  DISABLED = 'DISABLED',
  INVISIBLE = 'INVISIBLE',
}

export enum ERoles {
  GUEST = 'GUEST',
  CUSTOMER = 'CUSTOMER',
  USER = 'USER',
}

export enum ERoleActions {
  VIEW_LOG = 'VIEW_LOG',
}

<div class="hero-summary-card">
  <div class="hero-card-info">
    <h5 class="hero-card-info__title">{{ label | translate }}</h5>
    <p class="hero-card-info__value"
      [ngSwitch]="type">
      <span *ngSwitchCase="eSummaryCardType.PERCENTAGE">
        {{ value | number:'1.0-2' }}%
      </span>
      <span *ngSwitchCase="eSummaryCardType.CURRENCY">
        {{ value | currency:currency:'symbol':'1.2-2' }}
      </span>
      <span *ngSwitchCase="eSummaryCardType.NO_DATA">
        --
      </span>
      <span *ngSwitchDefault>{{ value | number: '1.0-2' }}</span>
    </p>
  </div>
  <p *ngIf="index !== undefined"
    class="hero-card-index"
    [class.hero-card-index--minus]="(index < 0 && !negativeIsExpected) || (index > 0 && negativeIsExpected)"
    [class.hero-card-index--plus]="(index > 0 && !negativeIsExpected) || (index < 0 && negativeIsExpected)">
    <span *ngIf="index === 0; else indexTpl">-</span>
    <ng-template #indexTpl>{{ index | heroNumber }}%</ng-template>
  </p>
</div>
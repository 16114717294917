import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hero-text-with-link',
  templateUrl: './text-with-link.component.html',
  styleUrls: ['./text-with-link.component.scss'],
})
export class TextWithLinkComponent implements OnInit {
  @Input() url = '';

  constructor() {}

  ngOnInit(): void {}

  onClick() {
    window.open(this.url, '_blank');
  }
}

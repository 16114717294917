import { SuperModel } from './super-model.model';

import { Customer } from './customer.model';
import { User } from './user.model';

export class AuthResult extends SuperModel {
  user: User;
  expiresAt: number;
  customers: Customer[];
  backToLogin: boolean;
  message: string;
  success: boolean;

  constructor(data: any) {
    super(data);
    if (data.user) {
      this.user = new User(data.user);
    }
    if (data.customers && data.customers.length) {
      this.customers = data.customers.map(customer => new Customer(customer));
    }
  }

  get serialised() {
    return {
      user: this.user,
      expiresAt: this.expiresAt,
    };
  }

  get canViewLogs() {
    return true;
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { ECurrency, ESummaryCardType } from '@enums';

@Component({
  selector: 'hero-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss'],
})
export class SummaryCardComponent implements OnInit {
  @Input() currency: ECurrency = ECurrency.EUR;
  @Input() label: string = null;
  @Input() value: number;
  @Input() index: number;
  @Input() type: ESummaryCardType = ESummaryCardType.VALUE;
  @Input() negativeIsExpected = false;

  eSummaryCardType = ESummaryCardType;

  constructor() {}

  ngOnInit(): void {}
}

<div *ngIf="totalPages && totalPages > 1"
  class="hero-pagination">
  <button [class.disabled]="!prevPage"
    (click)="changePage(prevPage)"
    class="btn hero-pagination__button-icon">
    <rmx-icon name="arrow-left-line"></rmx-icon>
  </button>
  <button *ngFor="let page of pages"
    (click)="changePage(page)"
    [class.active]="page === currentPage"
    class="btn">{{ page }}</button>
  <button [class.disabled]="!nextPage"
    (click)="changePage(nextPage)"
    class="btn hero-pagination__button-icon">
    <rmx-icon name="arrow-right-line"></rmx-icon>
  </button>
</div>
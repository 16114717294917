export enum EBitfStoreActions {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  RESET = 'RESET',
  SET_USER = 'SET_USER',
  UPDATE_USER = 'UPDATE_USER',
  BREAKPOINT = 'BREAKPOINT',
  SET_AUTH_TOKEN = 'SET_AUTH_TOKEN',
  SET_CONNECTORS = 'SET_CONNECTORS',
  SET_CONNECTOR_CONNECTION = 'SET_CONNECTOR_CONNECTION',
  SET_CAMPAIGN_WIZARD = 'SET_CAMPAIGN_WIZARD',
}

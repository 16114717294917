<div class="hero-sidebar-container hero-sidebar-container-admin">
  <div class="hero-sidebar-row">
    <div class="hero-sidebar-logo">
    </div>
  </div>
  <div class="hero-sidebar-row"
    *ngIf="user && user.role === eUserRole.CUSTOMER">
    <a routerLink="/campaigns/intro"
      class="hero-new-campaign hero-new-campaign--top">
      <span class="hero-new-campaign__btn-circle">
        <rmx-icon name="add-line"
          class="color__white"></rmx-icon>
      </span>
      {{ 'SIDEBAR.BUTTON.NEW_CAMPAIGN' | translate }}
    </a>
  </div>
  <div class="hero-sidebar-row">
    <hero-sidebar-menu [menuItems]="menuItems"></hero-sidebar-menu>
  </div>
</div>

import { BitfStore } from '@bitf/core/models/bitf-store.model';
import { EUserScope } from '@common/enums';

import { IAppTwoFactorAuthParams, IDashboardSetup } from '@interfaces';
import {
  AdvAccount,
  Customer,
  User,
  CampaignWizard,
  ConnectorStore,
  Project,
  UserCustomer,
  ConnectorConnection,
  InsightsStore,
} from '@models';

// NOTE: default values must be defined in the StoreService, not in the model
// (otherwise the default here will override the super Object.assign())
export class Store extends BitfStore {
  user: User;
  prop1: any;
  userCustomers: UserCustomer[];
  currentCustomer: Customer;
  currentProject: Project;
  userScope: EUserScope;
  insights: InsightsStore;
  campaignWizard: CampaignWizard;
  twoFactorAuth: IAppTwoFactorAuthParams;
  accounts: { [key: string]: AdvAccount[] };
  connectors: ConnectorStore;
  dashboardSetup: IDashboardSetup;
  connectorConnection: ConnectorConnection;

  constructor(storeData: Partial<Store>) {
    super(storeData);
  }
}

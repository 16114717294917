import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ChartConfiguration, ChartType } from 'chart.js';

import { chartDefaultConfig } from './chart-defaults.config';
import { IChartJsEvent } from './chart.interface';
import { backgroundColorPlugin } from './chart.plugins';

@Component({
  selector: 'hero-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() chartData: ChartConfiguration['data'];

  @Input() set chartType(chartType: ChartType) {
    this._chartType = chartType;
    this.mergeChartOptions();
  }

  @Input() set chartOptions(chartOptions: ChartConfiguration['options']) {
    this._chartOptionsInput = chartOptions;
    this.mergeChartOptions();
  }

  @Input() maxHeight = 500;

  @Input() set plugins(plugins: Array<unknown>) {
    this._plugins.push(...plugins);
  }

  @Output() chartClick: EventEmitter<IChartJsEvent> = new EventEmitter<IChartJsEvent>();
  @Output() chartHover: EventEmitter<IChartJsEvent> = new EventEmitter<IChartJsEvent>();

  _chartOptionsInput: ChartConfiguration['options'];
  _chartOptions: ChartConfiguration['options'];
  _chartType: ChartType = 'line';

  _plugins: Array<any> = [];

  constructor() {}

  ngOnInit(): void {
    this._plugins.push(backgroundColorPlugin);
  }

  private mergeChartOptions() {
    const defaultChartOptions = chartDefaultConfig[this._chartType];
    this._chartOptions = Object.assign({}, defaultChartOptions, this._chartOptionsInput);
  }
}

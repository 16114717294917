import { Injectable } from '@angular/core';

import { BitfStorageService } from '@bitf/services/storage/bitf-storage.service';

import { ConnectorStore, InsightsStore, Store } from '@models';

@Injectable({
  providedIn: 'root',
})
export class StoreStorageService extends BitfStorageService<Store> {
  constructor() {
    super({
      nativeStorage: sessionStorage,
      storageKey: 'store-storage',
      storageClass: Store,
      initialData: {
        accounts: {},
        insights: new InsightsStore({ filters: { showInsights: false }, types: [] }),
        connectors: new ConnectorStore({ filters: {} }),
      },
    });
  }
}

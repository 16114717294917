import { SuperModel } from './super-model.model';

export class RedirectUrl extends SuperModel {
  url: string;

  constructor(data: any) {
    super(data);
  }

  get serialised() {
    return {
      url: this.url,
    };
  }

  get canViewLogs() {
    return true;
  }
}

import { SuperModel } from './super-model.model';

export class StatisticsTimeSeries extends SuperModel {
  labels: string[];
  params?: { [key: string]: number[] };
  channels?: { [key: string]: number[] };

  get serialised() {
    return {};
  }
}

<div class="drv-panel-tabs">
  <ul [ngClass]="className"
    class="drv-panel-tabs-list">
    <li class="drv-panel-tabs-item"
      [class.selected]="isSelected(item) || item.isSelected"
      [class.disabled]="item.disabled"
      [class.drv-panel-tabs--narrow]="showCounter && !item.hideCounter"
      *ngFor="let item of items; let i = index; trackBy: trackByFn"
      (click)="onSelect(item)"
      routerLinkActive="item.selected">
      {{ item.label | translate }} <span class="drv-panel-tabs-counter"
        *ngIf="showCounter && !item.hideCounter">{{ item.counter || '0' }}</span>
    </li>
  </ul>
</div>
<nav class="hero-sidebar-nav">
  <ul class="hero-sidebar-nav__list">
    <li class="hero-sidebar-menu"
      *ngFor="let menuItem of menuItems; trackBy: trackByFn">
      <a [routerLink]="menuItem.url"
        routerLinkActive="hero-sidebar-menu__link--active"
        class="hero-sidebar-menu__link">
        <rmx-icon *ngIf="menuItem.icon"
          class="hero-sidebar-menu__icon"
          [name]="menuItem.icon"></rmx-icon>
        <span class="hero-sidebar-menu__title">{{ menuItem.label | translate }}</span>
      </a>
    </li>
  </ul>
</nav>
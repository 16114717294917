import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hero-loader',
  templateUrl: './loader.component.html',
  styleUrls: [
    '../../../../libs/bitforce/core/components/ui/loader/bitf-loader.component.scss',
    './loader.component.scss',
  ],
})
export class LoaderComponent implements OnInit {
  canShowLoader = false;
  constructor() {}

  ngOnInit(): void {}

  show() {
    this.canShowLoader = true;
  }

  hide() {
    this.canShowLoader = false;
  }
}

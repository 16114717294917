import { Injectable } from '@angular/core';

import { INgbModalConfig, NgBootstrapDialogService } from '@common/libs/ng-bootstrap';

import { IBitfDialog, IBitfDialogService } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class DialogsService implements IBitfDialogService<INgbModalConfig> {
  public dialog: IBitfDialog<INgbModalConfig>;
  constructor(public ngBootstrapDialog: NgBootstrapDialogService) {
    this.dialog = ngBootstrapDialog;
  }
}

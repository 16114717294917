<button class="drv-ok-cancel-close"
  (click)="close()">
  <rmx-icon name="close-line"></rmx-icon>
</button>
<h1 class="drv-ok-cancel-title">{{ title | translate }}</h1>
<div class="drv-ok-cancel-content">
  <p>{{ message | translate }}</p>
</div>

<div class="hero-modal-actions">
  <button (click)="onCancel()"
    [class]="noButtonClass">{{ cancelText | translate }}</button>
  <button (click)="onOk()"
    [class]="yesButtonClass">{{ okText | translate }}</button>
</div>

export enum ECampaignStatus {
  DRAFT = 'DRAFT',
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  FINISHED = 'FINISHED',
  PAUSED = 'PAUSED',
  FAILED = 'FAILED',
  FAVORITE = 'FAVORITE',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
}

export enum ECampaignAssetType {
  VIDEO = 'VIDEO',
  IMAGES = 'IMAGES',
  TEXT_CONTENT = 'TEXT_CONTENT',
  SOUND_FILES = 'SOUND_FILES',
}

export enum ECampaignChannelAdType {
  FB_9_16_RATIO_IMAGE = 'FB_9_16_RATIO_IMAGE',
  FB_1_1_RATIO_VIDEO = 'FB_1_1_RATIO_VIDEO',
  FB_9_16_RATIO_VIDEO = 'FB_9_16_RATIO_VIDEO',
  FB_1_1_RATIO_IMAGE = 'FB_1_1_RATIO_IMAGE',
}

export enum EUserRole {
  GUEST = 'GUEST',
  CUSTOMER = 'CUSTOMER',
  USER = 'USER',
}

export enum EUserScope {
  CUSTOMER = 'CUSTOMER',
  STAFF = 'STAFF',
  USER = 'USER',
  MANAGER = 'MANAGER',
}

export enum EAccountAction {
  REMOVE = 'REMOVE',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
}

import { SuperModel } from './super-model.model';

export class Project extends SuperModel {
  name: string;
  isDefault: boolean;

  constructor(data: any = {}) {
    super(data);
  }

  get serialised() {
    return {
      id: this.id,
      name: this.name,
    };
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IHeroIcon } from '@common/interfaces';

@Component({
  selector: 'hero-insight-icons',
  templateUrl: './insight-icons.component.html',
  styleUrls: ['./insight-icons.component.scss'],
})
export class InsightIconsComponent implements OnInit {
  @Input() items: IHeroIcon[] = [];
  @Output() switched: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  trackByFn(index: number) {
    return index;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';

import { User } from '@common/core/models';
import { EAccountAction, EUserRole } from '@common/enums';
import { IHeroDropdownItem } from '@common/interfaces';

@Component({
  selector: 'hero-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss'],
})
export class AccountCardComponent implements OnInit {
  @Input() user: User;
  @Input() userRole = EUserRole;
  @Input() isOwner = false;
  @Input() ownerId: string;

  @Output() remove: EventEmitter<User> = new EventEmitter<User>();

  actions = [
    // {
    //   label: bitfToTranslate('TABLE_MENU.LABEL.RESET_PASSWORD'),
    //   value: EAccountAction.CHANGE_PASSWORD,
    //   disabled: true,
    // },
    {
      label: bitfToTranslate('TABLE_MENU.LABEL.REMOVE'),
      value: EAccountAction.REMOVE,
      disabled: false,
    },
  ];

  constructor() {}

  onAction(event: IHeroDropdownItem) {
    switch (event.value) {
      case EAccountAction.REMOVE:
        this.remove.emit(this.user);
        break;
      default:
        throw new Error('Action not implemented');
    }
  }

  ngOnInit(): void {}
}

<div class="hero-google-analytics-card">
  <h3 class="hero-google-analytics-card__title">Google Analytics</h3>
  <button class="btn-edit btn-secondary"
    (click)="toggleEdit()"
    *ngIf="isSaved">
    <rmx-icon class="btn-return__icon"
      name="edit-box-line"></rmx-icon>
  </button>
  <form *ngIf="form"
    [formGroup]="form"
    (ngSubmit)="onFormSubmit()">
    <div class="form-group">
      <label class="hero-google-analytics-card__label"
        for="profile">{{ 'ADMIN.CONNECTION.LABEL.PROFILE' | translate }}</label>
      <hero-dropdown [items]="profileOptions"
        [selectedItem]="profileOptions ? profileOptions[0] : undefined"
        [className]="isSaved ? 'dropdown--disabled' : ''"
        formControlName="profile"
        areaHeight="100">
      </hero-dropdown>
    </div>
    <div class="form-group">
      <label class="hero-google-analytics-card__label"
        for="account">{{ 'ADMIN.CONNECTION.LABEL.ACCOUNT' | translate }}</label>
      <hero-dropdown [items]="accountOptions"
        [selectedItem]="accountOptions ? accountOptions[0] : undefined"
        [className]="isSaved ? 'dropdown--disabled' : ''"
        formControlName="account"
        areaHeight="100">
      </hero-dropdown>
    </div>
    <div class="form-group-submit d-flex justify-content-between align-items-center">
      <button type="submit"
        class="btn-submit btn-primary"
        *ngIf="!isSaved">
        {{ 'ADMIN.CONNECTION.BUTTON.SUBMIT' | translate }}
      </button>
    </div>
  </form>
</div>
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'hero-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent implements OnInit {
  @Input() value: string | number;
  @Input() className: string;
  @Input() name: string;
  @Input() checked: boolean;
  @Input() label: string;
  @Input() disabled: boolean;

  @Output() itemChecked: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  onChange(event) {
    if (event.target.checked) {
      this.itemChecked.emit(true);
    }
  }
}

import { SuperModel } from './super-model.model';

import { Project } from './project.model';
import { User } from './user.model';

export class Customer extends SuperModel {
  companyName: string;
  organizationName: string;
  organizationSize: string;
  organizationIndustry: string;
  platformReference: string;
  isOrganizationDataFilled: boolean;
  users?: User[];
  ownerFirstName: string;
  ownerLastName: string;
  projects?: Project[];

  constructor(data: any = {}) {
    super(data);

    if (data.users) {
      this.users = data.users.map(user => new User(user));
    }

    this.ownerFirstName = data.ownerfirstname;
    this.ownerLastName = data.ownerlastname;

    if (data.projects && data.projects.length) {
      this.projects = data.projects.map(project => new Project(project));
    }
  }

  get serialised() {
    return {
      companyName: this.companyName,
      organizationName: this.organizationName,
      organizationSize: this.organizationSize,
      organizationIndustry: this.organizationIndustry,
      platformReference: this.platformReference,
      isOrganizationDataFilled: this.isOrganizationDataFilled,
    };
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hero-protected-layout',
  templateUrl: './protected-layout.component.html',
  styleUrls: ['./protected-layout.component.scss'],
})
export class ProtectedLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

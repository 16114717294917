import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env/environment';
import { IBitfLoggerParams } from '@interfaces';
import { APP_VERSION, BUILD_DATE } from '@env/version';
import { CONSTANTS } from '@constants';
import { BitfLoggerService } from '@bitf/services/logger/bitf-logger.service';

@Injectable({
  providedIn: 'root',
})
export class BitfLogDnaSenderService {
  constructor(private httpClient: HttpClient, private bitfLoggerService: BitfLoggerService) {}

  init() {
    this.bitfLoggerService.logEvents$.subscribe(() => {
      this.sendLogs();
    });
  }

  sendLogs() {
    const keyName = `${environment.appName}-logs`;
    const logs = localStorage.getItem(keyName);
    if (logs) {
      const logMessagesArray: IBitfLoggerParams[] = JSON.parse(logs);

      const logLines = {
        lines: logMessagesArray.map(log => ({
          line: log.title || '',
          app: CONSTANTS['appName'] || environment.appName,
          level: log.level,
          env: environment.name,
          meta: { ...log, appVersion: APP_VERSION, buildDate: BUILD_DATE },
        })),
      };

      this.httpClient
        .post(`${environment.loggerConfig.logUrl}&hostname=${environment.name}&now=${Date.now()}`, logLines)
        .subscribe(() => {
          // Log added successfully, remove logs
          localStorage.removeItem(keyName);
        });
    }
  }
}

<header class="drv-header">
  <div class="d-flex">
    <a class="drv-header-logo-link d-flex align-items-center"
      routerLink="/insights/general">
      <img class="drv-header-logo"
        src="/assets/images/header/logo.svg"
        alt="">
    </a>
    <div class="drv-profile"
      [class.open]="isCompanyOpen">
      <div class="drv-profile-toggler"
        (click)="toggleCompanyDropdown($event)"
        (bitfClickOutside)="isCompanyOpen = false">
        <a href="#"
          class="drv-profile-avatar">
          <hero-insights-pointer *ngIf="isInsightVisible(eInsightCardType.PRIMARY_DOMAIN)"
            class="drv-insight-pointer"
            [type]="eInsightCardType.PRIMARY_DOMAIN">
          </hero-insights-pointer>
          <img *ngIf="company?.avatar"
            class="rounded-circle"
            [src]="company?.avatar"
            [alt]="customer.companyName">
          <span *ngIf="!company?.avatar">{{ customer.companyName | userInitials }}</span>
        </a>
        <div class="w-100 d-flex">
          <div class="d-flex flex-column">
            <span class="drv-profile-name">{{ customer.companyName }}</span>
            <span class="drv-profile-company">{{ company.domain }}</span>
          </div>
          <rmx-icon name="arrow-down-s-line"
            class="drv-profile-icon-dropdown"></rmx-icon>
        </div>
        <ul [class.d-none]="!isCompanyOpen"
          class="drv-profile-options">
          <li *ngFor="let item of companyMenuItems; let i = index; trackBy: trackByFn"
            class="drv-profile-option drv-profile-option-company"
            (click)="dropdownClickHandler(item)"
            [routerLink]="item.url"
            routerLinkActive="router-link-active">
            <div class="d-flex flex-column">
              <span class="drv-profile-name">{{ item.label | translate }}</span>
              <span class="drv-profile-company">{{ item.description | translate }}</span>
            </div>
            <rmx-icon name="edit-box-line"
              class="mr-0 ml-auto"></rmx-icon>
          </li>
          <li class="drv-profile-option drv-profile-option-company drv-profile-domain">
            <rmx-icon name="add-circle-fill"
              class="mr-2"></rmx-icon>
            <span class="drv-profile-domain-label">{{ 'HEADER.BUTTON.NEW_DOMAIN' | translate }}</span>
            <span class="drv-profile-plan">{{ 'HEADER.PLAN.UPGRADE' | translate }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="drv-circle-navigation">
    <div class="drv-circle-left">
      <a routerLink="/insights"
        routerLinkActive="active"
        class="drv-circle">
        <rmx-icon name="pie-chart-line"></rmx-icon>
      </a>
    </div>
    <div class="drv-circle-center">
      <a routerLink="/campaigns"
        routerLinkActive="active"
        class="drv-circle">
        <rmx-icon name="gallery-fill"></rmx-icon>
      </a>
    </div>
    <div class="drv-circle-right">
      <a routerLink="/connectors"
        routerLinkActive="active"
        class="drv-circle">
        <rmx-icon name="plug-2-line"></rmx-icon>
      </a>
    </div>
  </div>

  <div class="drv-profile"
    [class.open]="isOpen">
    <div class="drv-profile-toggler"
      (click)="toggleDropdown($event)"
      (bitfClickOutside)="isOpen = false">
      <a href="#"
        class="drv-profile-avatar">
        <hero-insights-pointer *ngIf="isInsightVisible(eInsightCardType.ACCOUNT_PROFILE) && !isUserFilled()"
          class="drv-insight-pointer"
          [type]="eInsightCardType.ACCOUNT_PROFILE">
        </hero-insights-pointer>
        {{ (user?.firstName + ' ' + user?.lastName) | userInitials }}
      </a>
      <div class="w-100 d-flex">
        <div class="d-flex flex-column">
          <span class="drv-profile-name">{{ user?.firstName }} {{ user?.lastName }}</span>
          <span class="drv-profile-company">{{ user?.companyName }}</span>
        </div>
        <rmx-icon name="arrow-down-s-line"
          class="drv-profile-icon-dropdown"></rmx-icon>
      </div>
      <ul [class.d-none]="!isOpen"
        class="drv-profile-options">
        <li *ngFor="let item of accountMenuItems; let i = index; trackBy: trackByFn"
          class="drv-profile-option"
          (click)="dropdownClickHandler(item)"
          [routerLink]="item.url ? [item.url] : []"
          routerLinkActive="router-link-active">
          <rmx-icon *ngIf="item.icon"
            class="color__theme-purple mr-2"
            [name]="item.icon"></rmx-icon>
          {{ item.label | translate }}
          <span *ngIf="item.url === '/accounts/profile'"
            class="drv-profile-plan">{{ 'HEADER.PLAN.BASIC' | translate }}</span>
        </li>
      </ul>
    </div>
  </div>
  <div class="drv-header-background"></div>
  <div class="drv-header-overlay"></div>
</header>
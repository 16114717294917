<div class="drv-upload-preview-container">
  <div class="drv-upload-preview-container__thumbnail-container">
    <ng-container *ngFor="let file of files; let i = index; trackBy: trackByFn">
      <div *ngIf="file.url"
        class="drv-upload-preview__item">
        <img *ngIf="!file.isProcessing"
          [src]="file.url"
          class="drv-upload-thumbnail"
          alt="">
        <div *ngIf="file.isProcessing"
          class="drv-upload-thumbnail">
          <rmx-icon name="loader-2-line"
            class="loader-icon"></rmx-icon>
        </div>
        <div class="drv-upload-preview__item-data">
          <p class="drv-upload-preview__item-name">{{ file.fileObject.name }}</p>
          <p class="drv-upload-preview__item-size">{{ file.fileObject.size }}kbs</p>
          <div *ngIf="file.isUploading"
            class="progress">
            <div class="progress-bar"
              role="progress-bar"
              style="width: 63%"
              aria-valuenow="100"
              aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
        </div>
        <div class="close-icon-container"
          (click)="onRemoveFile(i)">
          <rmx-icon name="delete-bin-4-line"
            class="close-icon"></rmx-icon>
        </div>
      </div>
    </ng-container>
  </div>

</div>
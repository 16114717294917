import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BitfFile } from '@bitf/core/models';

@Component({
  selector: 'hero-preview-upload-file',
  templateUrl: './preview-upload-file.component.html',
  styleUrls: ['./preview-upload-file.component.scss'],
})
export class PreviewUploadFileComponent implements OnInit {
  @Input() files: BitfFile[];
  @Output() removeFile = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  trackByFn(index) {
    return index;
  }

  onRemoveFile(index: number) {
    if (this.removeFile) {
      this.removeFile.emit(index);
    }
  }
}

import { Injectable } from '@angular/core';

import { BitfStoreService } from '@bitf/services/store/bitf-store.service';

import { ConnectorStore, InsightsStore, Store } from '@models';
import { StoreStorageService } from '@services';

@Injectable({
  providedIn: 'root',
})

// NOTE: if you use storeStorage, the initalData must defined in the storeStorage, otherwise here
// NOTE: inital data must be an object literal to be passed in the storeClass constructor not a storeClass
// instance this is to avoid to create something like new storeClass(justClone(StoreClassInstance));
// which will lead to problems
export class StoreService extends BitfStoreService<Store> {
  constructor(storeStorageService: StoreStorageService) {
    // NOTE: Verstion with storage
    super({
      storeClass: Store,
      storage: storeStorageService,
      initialData: {
        accounts: {},
        insights: new InsightsStore({ filters: { showInsights: false }, types: [] }),
        connectors: new ConnectorStore({ filters: {} }),
      },
    });
    // NOTE: version without storage and inital value here
    // super({ storeClass: Store, initialData: { prop1: 'Initial store value' } });
  }
}

import { trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { interval, Observable } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';

import { NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'hero-marketing-carousel',
  templateUrl: './marketing-carousel.component.html',
  styleUrls: ['./marketing-carousel.component.scss'],
  animations: [trigger('slider', [])],
})
export class MarketingCarouselComponent implements OnInit {
  images = [
    'assets/images/sliders/campaigns-slider.png',
    'assets/images/sliders/campaigns-slider.png',
    'assets/images/sliders/campaigns-slider.png',
  ];
  carouselBanner: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: this.images.length,
    speed: 600,
    interval: {
      timing: 4000,
      initialDelay: 1000,
    },
    point: {
      visible: true,
      hideOnSingleSlide: true,
    },
    load: 2,
    custom: 'banner',
    loop: true,
    touch: false,
    vertical: {
      enabled: false,
      height: 400,
    },
  };
  tempData: any[];
  public carouselTileItems$: Observable<number[]>;

  constructor() {}

  ngOnInit() {
    this.tempData = [];

    this.carouselTileItems$ = interval(500).pipe(
      startWith(-1),
      take(this.images.length),
      map(() => {
        const data = (this.tempData = [
          ...this.tempData,
          this.images[Math.floor(Math.random() * this.images.length)],
        ]);
        return data;
      })
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartDataset } from 'chart.js';

import { TranslateService } from '@ngx-translate/core';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { defaultLineDatasetConfig } from '@common/shared/components/chart/chart-defaults.config';

import { EColorsRGBA } from '@enums';
import { IHeroPanelLegend } from '@interfaces';
import { CampaignsTimeSeries, StatisticsTimeSeries } from '@models';

import { ITooltipItem, ITooltipItemData } from './insights-chart.interfaces';

@Component({
  selector: 'hero-insights-chart',
  templateUrl: './insights-chart.component.html',
  styleUrls: ['./insights-chart.component.scss'],
})
export class InsightsChartComponent implements OnInit {
  @Input() set statisticsTimeSeries(statisticsTimeSeries: StatisticsTimeSeries) {
    this._statisticsTimeSeries = statisticsTimeSeries;
    this.updateChartData();
  }
  get statisticsTimeSeries(): StatisticsTimeSeries {
    return this._statisticsTimeSeries;
  }

  availableColours = [
    EColorsRGBA.PURPLE_800,
    EColorsRGBA.YELLOW,
    EColorsRGBA.GREEN,
    EColorsRGBA.RED,
    EColorsRGBA.BLUE,
    EColorsRGBA.ORANGE_400,
    EColorsRGBA.BLUE_GRAY_400,
    EColorsRGBA.BLUE_GRAY_350,
  ];
  toTranslate = [
    bitfToTranslate('INSIGHTS.CHART.LABEL.INCOME'),
    bitfToTranslate('INSIGHTS.CHART.LABEL.ADVERTISING_SPEND'),
    bitfToTranslate('INSIGHTS.CHART.TOOLTIP.CAMPAIGN_START'),
    bitfToTranslate('INSIGHTS.CHART.TOOLTIP.CAMPAIGN_END'),
  ];
  translations: { [key: string]: string };

  _statisticsTimeSeries: StatisticsTimeSeries;
  _campaignsTimeSeries: CampaignsTimeSeries[];

  chartData: ChartConfiguration['data'];
  legend: IHeroPanelLegend[] = [];

  // plugins: Array<unknown> = [hideTooltipPlugin];
  campaignsChartData: number[] = [];

  campaignsTooltipItems: { [key: string]: ITooltipItem } = {};

  chartOptions: ChartConfiguration['options'] = {
    plugins: {
      legend: {
        display: false,
      },
      // tooltip: {
      //   mode: 'nearest',
      //   intersect: false,
      //   enabled: true,
      //   external: context => this.drawCampaignTooltip(context),
      // },
    },
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.translateService.get(this.toTranslate).subscribe(translations => {
      this.translations = translations;
      this.updateChartData();
    });
  }

  updateChartData() {
    if (!this.statisticsTimeSeries) {
      return;
    }

    if (!this.translations) {
      return;
    }

    this.chartData = {
      datasets: [],
      labels: this.statisticsTimeSeries.labels,
    };
    this.legend = [];

    let dataSource: { [key: string]: number[] } = {};
    let labelCreator: (string) => string;

    if (this.statisticsTimeSeries.channels) {
      dataSource = this.statisticsTimeSeries.channels;
      labelCreator = label => this.getChannelLabel(label);
    } else if (this.statisticsTimeSeries.params) {
      dataSource = this.statisticsTimeSeries.params;
      labelCreator = label => this.getParamLabel(label);
    }

    let index = 0;
    Object.entries(dataSource).forEach(dataSourceItem => {
      const [key, data] = dataSourceItem;
      const color = key === 'noData' ? EColorsRGBA.WHITE : this.availableColours[index];
      this.legend.push({ label: labelCreator(key), color, value: null });
      this.createDataset(labelCreator(key), data, this.availableColours[index]);
      index += 1;
    });
  }

  private getChannelLabel(channel: string) {
    return `CONNECTORS.CHANNEL.${channel.toUpperCase()}`;
  }

  private getParamLabel(param: string) {
    return `STATISTICS.PARAMS.${param.toUpperCase()}`;
  }

  createDataset(label: string, data: number[], color: EColorsRGBA) {
    this.chartData.datasets.push({
      ...defaultLineDatasetConfig,
      ...this.getColorConfig(color),
      label,
      data,
    });
  }

  // convertCampaignsToTooltipItems() {
  //   this.campaignsChartData = [];
  //   this.campaignsTooltipItems = {};
  //   this._campaignsTimeSeries.forEach((campaignsTimeSeries, index) => {
  //     const campaignsExists =
  //       campaignsTimeSeries && campaignsTimeSeries.campaigns && campaignsTimeSeries.campaigns.length;

  //     if (!this.campaignsTooltipItems[index] && campaignsExists) {
  //       this.campaignsTooltipItems[index] = {
  //         start: { campaigns: [], title: this.translations['INSIGHTS.CHART.TOOLTIP.CAMPAIGN_START'] },
  //         end: { campaigns: [], title: this.translations['INSIGHTS.CHART.TOOLTIP.CAMPAIGN_END'] },
  //       };
  //     }

  //     if (campaignsExists) {
  //       campaignsTimeSeries.campaigns.forEach(campaign => {
  //         if (campaign.isStart) {
  //           this.campaignsTooltipItems[index].start.campaigns.push(campaign);
  //         } else {
  //           this.campaignsTooltipItems[index].end.campaigns.push(campaign);
  //         }
  //       });
  //     }

  //     this.campaignsChartData.push(campaignsExists ? this.statisticsByTimeUnit.cost[index] : null);
  //   });
  // }

  private getColorConfig(color: EColorsRGBA): Partial<ChartDataset<'line'>> {
    return {
      backgroundColor: color,
      borderColor: color,
      pointBackgroundColor: color,
      pointBorderColor: color,
      pointHoverBackgroundColor: color,
      pointHoverBorderColor: color,
    };
  }

  private drawCampaignTooltip(context) {
    let tooltipEl = document.getElementById('heroInsightsTooltip');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'heroInsightsTooltip';
      tooltipEl.innerHTML = '<div class="hero-insights-tooltip__body"></div>';
      document.body.appendChild(tooltipEl);
    }

    const tooltipModel = context.tooltip;

    tooltipEl.classList.remove('above', 'below', 'no-transform');
    if (tooltipModel.yAlign) {
      tooltipEl.classList.add(tooltipModel.yAlign);
    } else {
      tooltipEl.classList.add('no-transform');
    }

    const campaignsDataPoint = tooltipModel.dataPoints[0];
    let innerHtml = '';

    if (
      campaignsDataPoint &&
      campaignsDataPoint.datasetIndex === 0 &&
      this.campaignsTooltipItems[campaignsDataPoint.dataIndex]
    ) {
      const tooltipData = this.campaignsTooltipItems[campaignsDataPoint.dataIndex];

      innerHtml += this.generateTooltipContent(tooltipData.start);
      innerHtml += this.generateTooltipContent(tooltipData.end);

      tooltipEl.style.display = 'block';
    } else {
      tooltipEl.style.display = 'none';
    }

    const container = tooltipEl.querySelector('.hero-insights-tooltip__body');
    container.innerHTML = innerHtml;

    const position = context.chart.canvas.getBoundingClientRect();

    tooltipEl.style.opacity = '1';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
    tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
    tooltipEl.style.pointerEvents = 'none';
  }

  private generateTooltipContent(tooltipItemData: ITooltipItemData) {
    let html = '';

    if (tooltipItemData.campaigns.length) {
      html = `
          <h3 class="hero-insights-tooltip__title">${tooltipItemData.title}</h3>
          `;
      html += this.mapCampaignsToTooltip(tooltipItemData);
    }
    return html;
  }

  private mapCampaignsToTooltip(data: ITooltipItemData) {
    return data.campaigns
      .map(campaign => {
        return `<p class="hero-insights-tooltip__description">${campaign.name}</p>`;
      })
      .join('');
  }
}

<div class="hero-first-connector-card">
  <h4 class="drv-insight-card-title">
    {{ 'INSIGHTS.CARD.FIRST_CONNECTOR.TITLE' | translate }}
  </h4>
  <p class="drv-insight-card-subtitle">
    {{ 'INSIGHTS.CARD.FIRST_CONNECTOR.SUBTITLE' | translate }}
  </p>
  <button class="btn btn-primary w-auto drv-insight-card-button"
    routerLink="/connectors"
    type="submit">
    {{ 'INSIGHTS.CARD.FIRST_CONNECTOR.BUTTON' | translate }}
  </button>
</div>

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IHeroChip, IHeroDropdownItem } from '@interfaces';
import { ApiCallStateService } from '@services';

@Component({
  selector: 'hero-filters-chips',
  templateUrl: './filters-chips.component.html',
  styleUrls: ['./filters-chips.component.scss'],
})
export class FiltersChipsComponent implements OnInit, OnDestroy {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() apiCallStateRequestPartKey: EApiRequestPartKeys;

  chips: IHeroChip[] = [];
  subscription: Subscription = new Subscription();

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {
    if (!this.apiCallStateRequestPartKey) {
      return;
    }
    this.updateChips();
    this.listenApiCallStateChanges();
  }

  onRemove(chip: IHeroChip) {
    const requestPart = this.requestPart;
    requestPart.formValue[chip.metadata.key] = requestPart.formValue[chip.metadata.key].filter(
      item => item.value !== chip.value
    );
    this.apiCallStateService.setStore(store => store, this.apiCallStateName);

    this.updateChips();
  }

  private updateChips() {
    this.chips = [];
    const filters = this.requestPart.formValue;

    if (!filters) {
      return;
    }

    Object.entries<IHeroDropdownItem[]>(filters).forEach(entry => {
      const [key, items] = entry;

      if (!Array.isArray(items)) {
        return;
      }

      items.forEach(item => {
        const { value, label } = item;
        this.chips.push({
          label,
          value,
          metadata: { key },
        });
      });
    });
  }

  private listenApiCallStateChanges() {
    this.subscription.add(
      this.apiCallStateService
        .getStateStore$(this.apiCallStateName)
        .pipe(tap(() => this.updateChips()))
        .subscribe()
    );
  }

  private get requestPart(): BitfApiRequestPart {
    return this.apiCallStateService.getRequestPart(this.apiCallStateName, this.apiCallStateRequestPartKey);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

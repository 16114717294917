import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { EApiRequestPartKeys, EChannelName } from '@enums';
import { EBitfApiRequestPartsNames } from '@enums';

export class ChannelApiRequestPart extends BitfApiRequestPart {
  constructor(defaultChannel = EChannelName.ALL) {
    super(EApiRequestPartKeys.CHANNEL_FILTER, EBitfApiRequestPartsNames.QUERY_PARAMS, {
      formValue: {
        channel: defaultChannel,
      },
    });
  }

  build(): void {
    this.part = undefined;
    if (!this.formValue || !this.formValue.channel) {
      return;
    }
    this.part = {
      channel: this.formValue.channel,
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { bitfGetFormControl } from '@bitf/utils/bitf-form.utils';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { IHeroDropdownItem } from '@interfaces';

@Component({
  selector: 'hero-google-analytics-card',
  templateUrl: './google-analytics-card.component.html',
  styleUrls: ['./google-analytics-card.component.scss'],
})
export class GoogleAnalyticsCardComponent implements OnInit {
  form: FormGroup;
  bitfGetFormControl = bitfGetFormControl;

  profileOptions: IHeroDropdownItem[] = [];
  accountOptions: IHeroDropdownItem[] = [];
  isSaved = false;

  toTranslate: Array<string> = [
    bitfToTranslate('COMMON.TOAST_MESSAGES.SUCCESS'),
    bitfToTranslate('ADMIN.NEW_USER.SUCCESS_TOAST_MESSAGE'),
    bitfToTranslate('COMMON.USER_ROLES.USER'),
    bitfToTranslate('COMMON.USER_ROLES.CUSTOMER'),
  ];

  translations: { [key: string]: string };

  constructor(private formBuilder: FormBuilder, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.initTranslations(() => {
      this.initProfileOptions();
      this.initAccountOptions();
      this.initForm();
    });
  }

  private initTranslations(postTranslations: () => void) {
    this.translateService.get(this.toTranslate).subscribe(translations => {
      this.translations = translations;
      postTranslations();
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      profile: [{ value: this.profileOptions[0] }, Validators.required],
      account: [{ value: null }, Validators.required],
    });
  }

  private initProfileOptions() {
    this.profileOptions = [
      { label: 'Profile 1', value: 'Option 1', isSelected: true },
      { label: 'Profile 2', value: 'Option 2' },
    ] as IHeroDropdownItem[];
  }

  private initAccountOptions() {
    this.accountOptions = [
      { label: 'Account 1', value: 'Option 1', isSelected: true },
      { label: 'Account 2', value: 'Option 2' },
    ] as IHeroDropdownItem[];
  }

  onFormSubmit() {
    // submit logic
    this.toggleEdit();
  }

  toggleEdit() {
    this.isSaved = !this.isSaved;
  }

  trackByFn(index) {
    return index;
  }
}

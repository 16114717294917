import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { fromEvent, of, Subscription, timer } from 'rxjs';
import { catchError, debounce, filter, map, switchMap } from 'rxjs/operators';

import { ISearchResultSet } from '@interfaces';
import { SearchService } from '@services';

import { ESearchStatus } from './search-bar.enum';

@Component({
  selector: 'hero-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;

  searchResultsSets: ISearchResultSet[] = [];
  resultsVisible = false;

  keyword = '';
  status = ESearchStatus.BEFORE_SEARCH;

  eSearchStatus = ESearchStatus;

  private subscription: Subscription = new Subscription();
  constructor(private searchService: SearchService) {}

  ngOnInit(): void {
    this.listenForInputChange();
  }

  listenForInputChange() {
    this.subscription.add(
      fromEvent<any>(this.searchInput.nativeElement, 'keyup')
        .pipe(
          map(event => event.target.value),
          debounce(() => timer(400)),
          filter(keyword => this.checkKeywordLength(keyword)),
          switchMap(keyword => {
            this.keyword = keyword;
            return this.searchService.search(keyword).pipe(
              catchError(error => {
                return of([]);
              })
            );
          })
        )
        .subscribe(searchResultsSets => {
          this.searchResultsSets = searchResultsSets;
          this.checkResults();
        })
    );
  }

  private checkResults() {
    if (!this.searchResultsSets || !this.searchResultsSets.length) {
      this.status = ESearchStatus.EMPTY_RESULT;
      return;
    }

    let resultExists = false;

    this.searchResultsSets.some(resultsSet => {
      if (resultsSet.searchResults.length) {
        resultExists = true;
        return true;
      }
    });

    this.status = resultExists ? ESearchStatus.RESULT_EXISTS : ESearchStatus.EMPTY_RESULT;
  }

  private checkKeywordLength(keyword: string): boolean {
    if (!keyword.length) {
      this.status = ESearchStatus.BEFORE_SEARCH;
      return false;
    }

    if (keyword.length < 3) {
      this.status = ESearchStatus.KEYWORD_TO_SHORT;
      return false;
    }
    return true;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

export enum EChannelName {
  FACEBOOK = 'FACEBOOK',
  GOOGLE_ADS = 'GOOGLE_ADS',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  ADROLL = 'ADROLL',
  CRITEO = 'CRITEO',
  ALL = 'ALL',
  MS_BING = 'MS_BING',
  SHOPIFY = 'SHOPIFY',
}

export enum EConnectorCategory {
  ALL = 'ALL',
  DATA_ANALYSIS = 'DATA_ANALYSIS',
  TRAFFIC_GENERATION = 'TRAFFIC_GENERATION',
  E_COMMERCE = 'E_COMMERCE',
}

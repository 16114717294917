import { Component, OnInit } from '@angular/core';

import { EToastMessageActionType } from '@common/enums';

import { IBitfToastData, IToastMessageAction } from '@web/interfaces';
import { ToastMessagesService } from '@web/core/services';
import { EBitfUiMessageType } from '@web/enums';

import { CONSTANTS } from '@constants';

@Component({
  selector: 'hero-toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.scss'],
})
export class ToastMessagesComponent implements OnInit {
  toastMessages: Array<IBitfToastData> = [];
  eBitfUiMessageType = EBitfUiMessageType;
  constructor(private toastMessagesService: ToastMessagesService) {}

  ngOnInit() {
    this.toastMessagesService.$messagesActions.subscribe((toastMessageAction: IToastMessageAction) => {
      switch (toastMessageAction.action) {
        case EToastMessageActionType.ADD:
          this.toastMessages.push(toastMessageAction.data);
          this.removeMessageAfterTimeout(toastMessageAction.data.id);
          break;
        case EToastMessageActionType.REMOVE_ALL:
          this.toastMessages = [];
          break;
        default:
      }
    });
  }

  removeMessageAfterTimeout(id: string) {
    setTimeout(() => {
      this.removeMessage(id);
    }, CONSTANTS.toastMessagesDisplayingTime);
  }

  removeMessage(id: string) {
    this.toastMessages = this.toastMessages.filter((message: IBitfToastData) => message.id !== id);
  }
}

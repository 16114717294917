import { Component } from '@angular/core';

@Component({
  selector: 'app-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
})
export class DesktopLayoutComponent {
  updateLayout() {
    // const data = this.route.snapshot.firstChild.data;
  }
}

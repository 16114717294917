import { Injectable, Injector } from '@angular/core';

import { BitfAuthGuard } from '@bitf/core/services/guards/bitf-auth.guard';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BitfAuthGuard {
  constructor(protected injector: Injector) {
    super(injector);
  }
}

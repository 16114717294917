import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  OnDestroy,
  ApplicationRef,
  ChangeDetectorRef,
} from '@angular/core';

import { DatatableComponent } from '@swimlane/ngx-datatable';

import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { SortApiRequestPart } from '@common/core/api-call-state';

import { IBitfApiPagination, IBitfApiSorting } from '@interfaces';
import {
  EApiCallStateNames,
  EBitfApiCallStateActions,
  EApiRequestPartKeys,
  EDataTableRowTreeStatus,
} from '@enums';
import { ApiCallStateService } from '@services';

import { IDataTableConfig, IDataTableColumn, IDataTableRow } from './data-table.interface';

@Component({
  selector: 'hero-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements OnInit, AfterViewInit, OnDestroy {
  static activeTables: Map<number, DatatableComponent> = new Map<number, DatatableComponent>();
  static nextTableID = 1;

  @Input() set tableConfig(tableConfig: IDataTableConfig) {
    if (!tableConfig) {
      return;
    }
    this._tableConfig = Object.assign({}, this.defaultDataTableConfig, tableConfig);
  }
  get tableConfig() {
    return this._tableConfig;
  }

  @Input() set rows(rows: Array<any>) {
    this._rows = rows;
    setTimeout(() => {
      this.recalculateTables();
    });
  }
  get rows() {
    return this._rows;
  }

  @Input() set columns(columns: Array<IDataTableColumn>) {
    if (!columns) {
      return;
    }
    this._columns = columns.map(column => Object.assign({}, this.defaultColumnConfig, column));
  }
  get columns() {
    return this._columns;
  }

  @Input() pagination: IBitfApiPagination;
  @Input() apiCallStateName: EApiCallStateNames;

  @ViewChild('tableComponent', { static: false }) tableComponent: DatatableComponent;

  id: number;
  defaultDataTableConfig: IDataTableConfig = {
    headerHeight: 50,
    rowHeight: 50,
    reorderable: false,
    columnMode: 'force',
    externalPaging: false,
    externalSorting: true,
    footerHeight: 100,
    virtualization: false,
    scrollbarH: true,
    scrollbarV: false,
    cssClasses: {
      sortAscending: 'datatable-icon-up',
      sortDescending: 'datatable-icon-down',
      pagerLeftArrow: 'datatable-icon-left',
      pagerRightArrow: 'datatable-icon-right',
      pagerPrevious: 'datatable-icon-prev',
      pagerNext: 'datatable-icon-skip',
    },
  };
  defaultColumnConfig: IDataTableColumn = {
    name: '',
    prop: '',
    resizeable: false,
    draggable: false,
    sortable: false,
  };

  _tableConfig: IDataTableConfig;
  _columns: Array<IDataTableColumn>;
  _rows: Array<any>;

  // this is needed to avoid lost of translation in case of use extractTranslations...
  toTranslate = [bitfToTranslate('COMMON.DATA_TABLE.EMPTY_MESSAGE')];

  sorts: Array<any> = [];
  constructor(private apiCallStateService: ApiCallStateService, private changeDetection: ChangeDetectorRef) {}

  ngOnInit() {
    this.initSort();
  }

  ngAfterViewInit(): void {
    this.id = DataTableComponent.nextTableID;
    DataTableComponent.nextTableID += 1;
    DataTableComponent.activeTables.set(this.id, this.tableComponent);
  }

  initSort() {
    // const sortRequestPart = this.sortRequestPart;
    // if (
    //   !sortRequestPart ||
    //   !sortRequestPart.formValue ||
    //   !sortRequestPart.formValue.sorting ||
    //   !sortRequestPart.formValue.sorting.length
    // ) {
    //   return;
    // }
    // this.sorts = sortRequestPart.formValue.sorting.map((sort: IBitfApiSorting) => {
    //   return {
    //     prop: sort.property,
    //     dir: sort.direction.toLowerCase(),
    //   };
    // });
  }

  onPageChange(pagination) {
    // if (!this.apiCallStateName) {
    //   throw Error('DataTable: apiCallStateName undefined');
    // }
    // const apiCallState = this.apiCallStateService.getApiCallState(this.apiCallStateName);
    // apiCallState.apiRequest.page = pagination.offset;
    // this.apiCallStateService.dispatchApiCallState(EBitfApiCallStateActions.UPDATE, this.apiCallStateName);
  }

  get sortRequestPart() {
    let sortRequestPart: SortApiRequestPart = null;
    try {
      sortRequestPart = this.apiCallStateService.getRequestPart(
        this.apiCallStateName,
        EApiRequestPartKeys.SORTING
      );
    } catch {}
    return sortRequestPart;
  }

  onSort(event) {
    // const tableSorting = event.sorts.length ? event.sorts[0] : null;
    // const sorting = [];
    // if (tableSorting) {
    //   sorting.push({
    //     property: tableSorting.prop,
    //     direction: tableSorting.dir.toUpperCase(),
    //   } as IBitfApiSorting);
    // }
    // const sortRequestPart = this.apiCallStateService.getRequestPart(
    //   this.apiCallStateName,
    //   EApiRequestPartKeys.SORTING
    // );
    // sortRequestPart.formValue = {
    //   sorting,
    // };
    // this.apiCallStateService.setStore(store => store, this.apiCallStateName);
  }

  toggleExpandRow(row: IDataTableRow) {
    row.treeStatus =
      row.treeStatus === EDataTableRowTreeStatus.COLLAPSED
        ? EDataTableRowTreeStatus.EXPANDED
        : EDataTableRowTreeStatus.COLLAPSED;

    this.rows = [...this.rows];
    this.tableComponent.count = Math.max(this.tableComponent.count, this.rows.length);

    setTimeout(() => {
      this.changeDetection.detectChanges();
    });
  }

  recalculateTables() {
    DataTableComponent.activeTables.forEach((tableComponent: DatatableComponent) => {
      if (tableComponent) {
        // tableComponent.recalculateAll();
      }
    });
  }

  ngOnDestroy(): void {
    DataTableComponent.activeTables.delete(this.id);
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { ApiCallStateService } from '@services';
import { EChannelName, EApiCallStateNames, EApiRequestPartKeys } from '@enums';
import { IHeroIcon } from '@interfaces';

@Component({
  selector: 'hero-channel-switch',
  templateUrl: './channel-switch.component.html',
  styleUrls: ['./channel-switch.component.scss'],
})
export class ChannelSwitchComponent implements OnInit {
  @Input() set availableChannels(availableChannels: EChannelName[]) {
    if (!availableChannels || !availableChannels.length) {
      return;
    }
    this.updateItems(availableChannels);
  }

  @Input() apiCallStateNames: EApiCallStateNames[];

  items: IHeroIcon[] = [
    {
      label: 'Google analytics',
      url: '/assets/images/marketing/google-analytics.svg',
      channel: EChannelName.ALL,
      disabled: true,
    },
    {
      label: 'Google ads',
      url: '/assets/images/marketing/google-ads.svg',
      channel: EChannelName.GOOGLE_ADS,
      disabled: true,
    },
    {
      label: 'Facebook',
      url: '/assets/images/marketing/facebook.svg',
      channel: EChannelName.FACEBOOK,
      disabled: true,
    },
    {
      label: 'Ms Bing',
      url: '/assets/images/marketing/google-analytics.svg',
      channel: EChannelName.MS_BING,
      disabled: true,
    },
    {
      label: 'Criteo',
      url: '/assets/images/marketing/criteo.svg',
      disabled: true,
    },
  ];

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {}

  onSwitch(channel) {
    this.apiCallStateNames.forEach(apiCallStateName => {
      const currentChannel = this.getRequestPart(apiCallStateName).formValue.channel || undefined;
      if (currentChannel === channel) {
        return;
      }

      this.getRequestPart(apiCallStateName).formValue = {
        channel,
      };
      this.apiCallStateService.setStore(() => {}, apiCallStateName);
    });
  }

  private getRequestPart(apiCallStateName: EApiCallStateNames): BitfApiRequestPart {
    return this.apiCallStateService.getRequestPart(apiCallStateName, EApiRequestPartKeys.CHANNEL_FILTER);
  }

  private updateItems(availableChannels: EChannelName[]) {
    this.items = this.items.map(item => {
      return {
        ...item,
        disabled: !(
          availableChannels.includes(item.channel) ||
          (item.channel === EChannelName.ALL && availableChannels.includes(EChannelName.GOOGLE_ANALYTICS))
        ),
      };
    });
  }
}

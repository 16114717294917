<ng-container *ngIf="canShowLoader">
  <div class="loader-overlay">
    <div class="loader-overlay-content">
      <div class="spinner">
        <div class="bounce1"></div>
        <div class="bounce2"></div>
        <div class="bounce3"></div>
      </div>
    </div>
  </div>
</ng-container>
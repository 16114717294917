<div>
  <p class="drv-insight-card-panel-time">{{ createdAt | dateAgo }}</p>
  <div class="drv-insight-card-panel"
    [class.active]="type === insights?.filters?.selectedType"
    (click)="onSelectCard()">
    <div class="drv-insight-close"
      (click)="onCloseClick($event)">
      <rmx-icon name="close-line"></rmx-icon>
    </div>
    <h4 class="drv-insight-card-title">{{ title | translate }}</h4>
    <p class="drv-insight-card-subtitle">{{ description | translate }}</p>
    <button class="btn w-auto drv-insight-card-button"
      type="submit"
      (click)="onActionClick()">
      {{ buttonText }}
    </button>
  </div>
</div>

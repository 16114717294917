<div class="drv-no-data-card">
  <div class="drv-card-info">
    <h5 class="drv-card-info__title">{{ 'COMMON.LABEL.METRIC' | translate }}</h5>
    <p class="drv-card-info__value">{{ 'COMMON.LABEL.NO_DATA' | translate }}</p>
    <figure class="drv-card-figure">
      <rmx-icon name="plug-2-line"></rmx-icon>
    </figure>
    <rmx-icon *ngIf="showFocus"
      name="focus-3-line"
      class="rmx-focus"></rmx-icon>
  </div>
  <p class="drv-card-index">
    <span>-</span>
  </p>
</div>
import { Component, OnInit, Input } from '@angular/core';

import { EApiCallStateNames, EBitfApiCallStateActions } from '@enums';
import { IHeroDropdownItem } from '@interfaces';
import { CONSTANTS } from '@constants';
import { ApiCallStateService } from '@services';

@Component({
  selector: 'hero-data-table-page-size',
  templateUrl: './data-table-page-size.component.html',
  styleUrls: ['./data-table-page-size.component.scss'],
})
export class DataTablePageSizeComponent implements OnInit {
  @Input() apiCallStateName: EApiCallStateNames;
  @Input() sizeOptions: Array<IHeroDropdownItem>;
  selectedItem: IHeroDropdownItem;

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit() {
    const apiCallState = this.apiCallStateService.getApiCallState(this.apiCallStateName);
    if (!this.sizeOptions) {
      this.sizeOptions = CONSTANTS.dataTableSizeOptions.map(size => {
        const item: IHeroDropdownItem = {
          value: size,
          label: size.toString(),
        };
        if (apiCallState.apiRequest.size === size) {
          item.isSelected = true;
          this.selectedItem = item;
        }
        return item;
      });
    }
  }

  onSizeChange(selectedOption: IHeroDropdownItem) {
    const apiCallState = this.apiCallStateService.getApiCallState(this.apiCallStateName);
    apiCallState.apiRequest.size = selectedOption.value;
    apiCallState.apiRequest.page = 0;
    this.apiCallStateService.dispatchApiCallState(EBitfApiCallStateActions.UPDATE, this.apiCallStateName);
  }
}

import { Component, Input, OnInit } from '@angular/core';

import { ECampaignStatus, EChannelName, ECurrency } from '@common/enums';

@Component({
  selector: 'hero-campaign-card',
  templateUrl: './campaign-card.component.html',
  styleUrls: ['./campaign-card.component.scss'],
})
export class CampaignCardComponent implements OnInit {
  @Input() title: string;
  @Input() channels: EChannelName[] = [];
  @Input() status: ECampaignStatus;
  @Input() label: string;
  @Input() isFavorite: boolean;
  @Input() goal: number;
  @Input() goalAchieved: number;
  @Input() budget: number;
  @Input() budgetUsed: number;
  @Input() totalTime: number;
  @Input() timeElapsed: number;
  @Input() currency: ECurrency = ECurrency.EUR;

  goalProgress: number;
  timeProgress: number;
  budgetProgress: number;
  timeLeft: number;

  campaignStatus = ECampaignStatus;

  constructor() {}

  ngOnInit(): void {
    this.calculateProgress();
  }

  calculateProgress() {
    this.timeLeft = Number(this.totalTime - this.timeElapsed);
    this.goalProgress = Number(((this.goalAchieved / this.goal) * 100).toFixed());
    this.budgetProgress = Number(((this.budgetUsed / this.budget) * 100).toFixed());
    this.timeProgress = Number((100 - (this.timeElapsed / this.totalTime) * 100).toFixed());
  }

  trackByFn(index: number) {
    return index;
  }

  getChannelIcon(channel: EChannelName) {
    return `assets/images/marketing/${channel.toLowerCase()}.svg`;
  }
}

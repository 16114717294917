import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hero-campaign-card-blank',
  templateUrl: './campaign-card-blank.component.html',
  styleUrls: ['./campaign-card-blank.component.scss'],
})
export class CampaignCardBlankComponent implements OnInit {
  @Input() className: string;
  @Input() link = '/campaigns';

  constructor() {}

  ngOnInit(): void {}
}

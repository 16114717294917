import { bitfToTranslate } from '../utils/bitf-translate.utils';

export const BITF_PARSER_HEADER_NAME = 'BitfParserType';

export const BITF_VALIDATION_MESSAGES = {
  required: bitfToTranslate('BITF.VALIDATION_ERROR.REQUIRED'),
  email: bitfToTranslate('BITF.VALIDATION_ERROR.EMAIL'),
  maxlength: bitfToTranslate('BITF.VALIDATION_ERROR.MAX_LENGTH'),
  minlength: bitfToTranslate('BITF.VALIDATION_ERROR.MIN_LENGTH'),
  min: bitfToTranslate('BITF.VALIDATION_ERROR.MIN'),
  max: bitfToTranslate('BITF.VALIDATION_ERROR.MIN'),
  requiredTrue: bitfToTranslate('BITF.VALIDATION_ERROR.REQUIRED_TRUE'),
  nullValidator: bitfToTranslate('BITF.VALIDATION_ERROR.NULL'),
  emailsList: bitfToTranslate('BITF.VALIDATION_ERROR.EMAILS_LIST'),
  atLeastRequired: bitfToTranslate('BITF.VALIDATION_ERROR.AT_LEAST_REQUIRED'),
};

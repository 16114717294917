import { SuperModel } from './super-model.model';

import { StatisticsParam } from './statistics-param.model';

export class CampaignStatistics extends SuperModel {
  campaignId: string;
  campaignName: string;
  params: StatisticsParam[];

  constructor(data: any = {}) {
    super(data);
    this.params =
      data.params && data.params.length ? data.params.map(paramItem => new StatisticsParam(paramItem)) : [];
  }

  get serialised() {
    return {};
  }
}

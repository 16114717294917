import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

import { CampaignStatistics, Statistics, StatisticsParam, StatisticsTimeSeries } from '@common/core/models';
import { EChannelName, EStatisticsParam, ETimeUnit } from '@common/enums';
import { IBitfApiRequest } from '@common/interfaces';

@Injectable({
  providedIn: 'root',
})
export class StatisticsService extends BitfApiService {
  constructor(public injector: Injector) {
    super('statistics', injector);
  }

  getSummary(request: IBitfApiRequest, channel: EChannelName) {
    return this.request<Statistics>({
      method: 'GET',
      path: channel === EChannelName.ALL ? '/global-summary' : '/summary',
      ...request,
    });
  }

  getDividedByTimeUnit(request: IBitfApiRequest, channel: EChannelName) {
    return this.request<Statistics>({
      method: 'GET',
      path: channel === EChannelName.ALL ? '/global-divided-by-time-unit' : '/divided-by-time-unit',
      ...request,
    });
  }

  getGeneralParams(startDate: Date, endDate: Date) {
    return this.request<StatisticsParam[]>({
      method: 'GET',
      modelMapper: 'statistics-param',
      path: '/general',
      queryParams: {
        startDate,
        endDate,
      },
    });
  }

  getGeneralTimeSeries(startDate: Date, endDate: Date, timeUnit: ETimeUnit) {
    return this.request<StatisticsTimeSeries>({
      method: 'GET',
      modelMapper: 'statistics-time-series',
      path: '/general/time-series',
      queryParams: {
        startDate,
        endDate,
        timeUnit,
      },
    });
  }

  getCampaignsParams(startDate: Date, endDate: Date, params: EStatisticsParam[]) {
    return this.request<CampaignStatistics[]>({
      method: 'GET',
      modelMapper: 'campaign-statistics',
      path: '/campaigns',
      queryParams: {
        startDate,
        endDate,
        params: params.join(','),
      },
    });
  }

  getCampaignsTimeSeriesByChannel(
    startDate: Date,
    endDate: Date,
    timeUnit: ETimeUnit,
    campaignId: string,
    param: EStatisticsParam
  ) {
    return this.request<StatisticsTimeSeries>({
      method: 'GET',
      modelMapper: 'statistics-time-series',
      path: '/campaigns/time-series-by-channel',
      queryParams: {
        startDate,
        endDate,
        timeUnit,
        campaignId,
        param,
      },
    });
  }

  getCampaignsTimeSeriesByParam(
    startDate: Date,
    endDate: Date,
    timeUnit: ETimeUnit,
    campaignId: string,
    params: EStatisticsParam[]
  ) {
    return this.request<StatisticsTimeSeries>({
      method: 'GET',
      modelMapper: 'statistics-time-series',
      path: '/campaigns/time-series-by-params',
      queryParams: {
        startDate,
        endDate,
        timeUnit,
        campaignId,
        params: params.join(','),
      },
    });
  }
}

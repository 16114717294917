import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { StoreService } from '../store.service';

@Injectable({
  providedIn: 'root',
})
export class WelcomeGuard implements CanActivate {
  private router: Router;
  private storeService: StoreService;

  constructor(protected injector: Injector) {
    this.router = injector.get(Router);
    this.storeService = injector.get(StoreService);
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const customer = this.storeService.store.currentCustomer;

    if (!customer?.isOrganizationDataFilled) {
      this.router.navigate(['/welcome']);
      return false;
    }

    return true;
  }
}

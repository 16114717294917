import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'hero-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  trackByFn(index: number) {
    return index;
  }
}

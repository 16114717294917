<ngx-datatable #tableComponent
  class="w-100"
  *ngIf="tableConfig && columns"
  [rows]="rows"
  [columns]="columns"
  [headerHeight]="tableConfig.headerHeight"
  [rowHeight]="tableConfig.rowHeight"
  [columnMode]="tableConfig.columnMode"
  [cssClasses]="tableConfig.cssClasses || {}"
  [externalPaging]="tableConfig.externalPaging"
  [externalSorting]="tableConfig.externalSorting"
  [footerHeight]="tableConfig.footerHeight"
  [headerHeight]="tableConfig.headerHeight"
  [messages]="tableConfig.messages"
  [loadingIndicator]="tableConfig.loadingIndicator"
  [reorderable]="tableConfig.reorderable"
  [rowHeight]="tableConfig.rowHeight"
  [rowIdentity]="tableConfig.rowIdentity"
  [scrollbarH]="tableConfig.scrollbarH"
  [scrollbarV]="tableConfig.scrollbarV"
  [selectCheck]="tableConfig.selectCheck"
  [selected]="tableConfig.selected || []"
  [selectionType]="tableConfig.selectionType"
  [trackByProp]="tableConfig.trackByProp"
  [rowClass]="tableConfig.rowClass"
  [virtualization]="tableConfig.virtualization"
  [limit]="pagination?.size"
  [offset]="pagination?.page"
  [count]="pagination?.totalItems"
  (page)="onPageChange($event)"
  (sort)="onSort($event)"
  [sorts]="sorts"
  [treeFromRelation]="tableConfig.treeFromRelation"
  [treeToRelation]="tableConfig.treeToRelation">
  <!-- <ngx-datatable-row-detail [rowHeight]="auto">
    <ng-template let-row="row"
      let-expanded="expanded"
      ngx-datatable-row-detail-template>
      <div *ngIf="row.children">
        <drv-data-table-row-details [type]="rowDetailsType"
          [items]="row.children"></drv-data-table-row-details>
      </div>
    </ng-template>
  </ngx-datatable-row-detail> -->
</ngx-datatable>

export enum EApiQueryPartOperator {
  LT = 'LT',
  LTE = 'LTE', // less or equal
  MT = 'MT',
  MTE = 'MTE', // more or equal
  EQ = 'EQ',
  LIKE = 'LIKE',
  IN = 'IN',
  OR = 'OR',
  AND = 'AND',
}

export enum EApiQueryPartPropertyType {
  DATE = 'DATE',
}

import { Component, Injector, OnInit } from '@angular/core';

import { ModalBaseComponent } from '../base-modal/base-modal';

@Component({
  selector: 'hero-register-terms-dialog',
  templateUrl: './register-terms-dialog.component.html',
  styleUrls: ['./register-terms-dialog.component.scss'],
})
export class RegisterTermsDialogComponent extends ModalBaseComponent implements OnInit {
  toTranslate = [];
  header: string;
  optionalLabel: string;

  title: string;
  cancelText: string;
  okText: string;
  yesButtonClass: string;
  noButtonClass: string;
  showSuccessMessage = true;

  constructor(public injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initClasses();
  }

  onOk() {
    this.bitfDialogRef.close({ status: this.eBitfCloseEventStatus.OK });
  }

  onCancel() {
    this.bitfDialogRef.close({ status: this.eBitfCloseEventStatus.CANCEL });
  }

  private initClasses() {
    this.yesButtonClass = 'btn ' + (this.yesButtonClass || 'btn-primary fs-14');
    this.noButtonClass = 'btn ' + (this.noButtonClass || 'btn-outline btn-cancel fs-14');
  }
}

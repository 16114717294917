<label [class.disabled]="disabled"
  class="drv-radio">
  <input type="radio"
    [id]="value"
    [name]="name"
    [value]="value"
    [checked]="checked"
    [disabled]="disabled"
    (change)="onChange($event)" />
  <div class="drv-radio-container">
    <rmx-icon name="check-line"></rmx-icon>
  </div>
  <span *ngIf="label"
    class="drv-radio-label__title">{{ label }}</span>
</label>
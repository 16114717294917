import { Injectable, OnInit } from '@angular/core';

import { Subject } from 'rxjs';

import { BitfToastMessagesService } from '@bitf/services/toast-messages/bitf-toast-messages.service';
import { IBitfToastMessagesService } from '@bitf/services/toast-messages/bitf-toast-messages.interface';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

import { IBitfToastData, IToastMessageAction } from '@interfaces';
import { EToastMessageActionType } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService extends BitfToastMessagesService implements IBitfToastMessagesService {
  public $messagesActions: Subject<IToastMessageAction> = new Subject<IToastMessageAction>();

  constructor() {
    super();
  }

  show(data: IBitfToastData) {
    data.id = Math.floor(Date.now()).toString();
    this.$messagesActions.next({
      data,
      action: EToastMessageActionType.ADD,
    });
  }

  clearAllMessages(): void {
    this.$messagesActions.next({
      action: EToastMessageActionType.REMOVE_ALL,
    });
  }
}

import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HammerModule } from '@angular/platform-browser';

import { TranslateModule } from '@ngx-translate/core';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { LightboxModule } from 'ngx-lightbox';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ChartsModule } from 'ng2-charts';
import { Angular2AirDatepickerModule } from 'angular2-air-datepicker';
import { NguCarouselModule } from '@ngu/carousel';

import { BitfFormBuilderService } from '@bitf/core/form';
import { BitfFormControlTranslatePipe } from '@bitf/pipes/translate/bitf-form-control-translate.pipe';

import { BitfTouchEventDirective } from '@bitf/directives/bitf-touch-events/bitf-touch-event.directive';
import { BitfUiRoleManagerDirective } from '@bitf/directives/ui-role-manager/bitf-ui-role-manager.directive';
import { BitfClickOutsideDirective } from '@bitf/directives/bitf-click-outside.directive';
import { BitfClipboardDirective } from '@bitf/directives/bitf-clipbard.directive';
import { BitfDndFileComponent } from '@bitf/core/components/ui/dnd-file/bitf-dnd-file.component';

import {
  HeroValidationMessageDirective,
  HeroLoaderDirective,
  LoaderComponent,
  HeroNotEmptyDirective,
} from './directives';
import { HeroValidationMessageComponent } from './directives/validation-message/validation-message.component';

import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { HeroDatePipe } from './pipes/hero-date/hero-date.pipe';
import { HeroNumberPipe } from './pipes/number/number.pipe';
import { DateAgoPipe } from './pipes/date-ago/date-ago.pipe';
import { UserInitialsPipe } from './pipes/user-initials/user-initials.pipe';

import { MATERIAL_MODULES } from './material-modules';
import { NG_BOOTSTRAP_MODULES } from './ng-bootstrap-modules';

import { MODALS } from './components/modals';

import {
  SearchComponent,
  HeaderComponent,
  AccountCardComponent,
  ChipsComponent,
  MarketingCarouselComponent,
  CommonCellsTemplatesComponent,
  RadioComponent,
  RadioBoxComponent,
  PreviewUploadFileComponent,
  PreviewUploadFileDetailComponent,
  DropdownComponent,
  CharsCounterComponent,
  InputWithChipsComponent,
  FilesUploaderComponent,
  PreviewWebsiteMetaComponent,
  DataTableComponent,
  DataTablePageSizeComponent,
  SidebarComponent,
  SidebarMenuComponent,
  SearchBarComponent,
  ChartComponent,
  SummaryCardComponent,
  MetricCardComponent,
  NoDataCardComponent,
  DateRangeFilterComponent,
  TimeUnitSelectorComponent,
  DatepickerComponent,
  ConnectionCardComponent,
  ComingSoonCardComponent,
  GoogleAnalyticsCardComponent,
  InformationCardComponent,
  FirstConnectorCardComponent,
  InsightCardComponent,
  InsightsPointerComponent,
  PanelLegendsComponent,
  PanelTabsComponent,
  InsightIconsComponent,
  ToggleButtonComponent,
  ChannelSwitchComponent,
  TableMenuComponent,
  TextWithCopyComponent,
  TextWithLinkComponent,
  PaginationComponent,
  FiltersChipsComponent,
  CampaignCardBlankComponent,
  CampaignCardComponent,
  NoConnectionCardComponent,
  ChannelBoxComponent,
  AvatarFileUploaderComponent,
} from './components';

import {
  RiCloseLine,
  RiCloseFill,
  RiArticleLine,
  RiArrowRightLine,
  RemixIconModule,
  RiErrorWarningLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiAddCircleFill,
  RiCloseCircleFill,
  RiSearchLine,
  RiCheckLine,
  RiSearch2Line,
  RiNotificationLine,
  RiAddLine,
  RiDonutChartFill,
  RiSettingsLine,
  RiArrowLeftLine,
  RiStackLine,
  RiVipCrownLine,
  RiGitBranchFill,
  RiGitCommitLine,
  RiUserSettingsLine,
  RiLinkUnlink,
  RiCalendarLine,
  RiEditBoxLine,
  RiEdit2Line,
  RiLockLine,
  RiGalleryLine,
  RiMoreFill,
  RiDeleteBin5Line,
  RiFileCopyLine,
  RiExternalLinkLine,
  RiSubtractLine,
  RiRefreshLine,
  RiPlug2Line,
  RiPieChartLine,
  RiGalleryFill,
  RiLogoutCircleLine,
  RiDeleteBin4Line,
  RiFocus3Line,
  RiTimerLine,
  RiDownloadCloudLine,
  RiStarLine,
  RiRestartLine,
  RiStarFill,
  RiLoader2Line,
} from 'angular-remix-icon';

import { OkCancelDialogComponent } from './components/modals/ok-cancel-dialog/ok-cancel-dialog.component';

const SHARED_MODULES = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  HammerModule,
  TranslateModule,
  RouterModule,
  PerfectScrollbarModule,
  LightboxModule,
  NgxDatatableModule,
  ChartsModule,
  Angular2AirDatepickerModule,
  NguCarouselModule,
  MATERIAL_MODULES,
  NG_BOOTSTRAP_MODULES,
];
const SHARED_COMPONENTS = [
  HeroValidationMessageComponent,
  BitfDndFileComponent,
  SearchComponent,
  HeaderComponent,
  AccountCardComponent,
  ChipsComponent,
  CommonCellsTemplatesComponent,
  RadioComponent,
  RadioBoxComponent,
  PreviewUploadFileComponent,
  PreviewUploadFileDetailComponent,
  DropdownComponent,
  CharsCounterComponent,
  InputWithChipsComponent,
  FilesUploaderComponent,
  PreviewWebsiteMetaComponent,
  DataTableComponent,
  DataTablePageSizeComponent,
  MarketingCarouselComponent,
  SidebarComponent,
  SidebarMenuComponent,
  SearchBarComponent,
  ChartComponent,
  SummaryCardComponent,
  MetricCardComponent,
  NoDataCardComponent,
  DateRangeFilterComponent,
  TimeUnitSelectorComponent,
  DatepickerComponent,
  ConnectionCardComponent,
  ComingSoonCardComponent,
  GoogleAnalyticsCardComponent,
  InformationCardComponent,
  FirstConnectorCardComponent,
  InsightCardComponent,
  InsightsPointerComponent,
  PanelLegendsComponent,
  PanelTabsComponent,
  InsightIconsComponent,
  ToggleButtonComponent,
  ChannelSwitchComponent,
  TableMenuComponent,
  TextWithCopyComponent,
  TextWithLinkComponent,
  PaginationComponent,
  OkCancelDialogComponent,
  FiltersChipsComponent,
  LoaderComponent,
  CampaignCardBlankComponent,
  CampaignCardComponent,
  NoConnectionCardComponent,
  ChannelBoxComponent,
  AvatarFileUploaderComponent,
];
const SHARED_DIRECTIVES = [
  HeroValidationMessageDirective,
  BitfUiRoleManagerDirective,
  BitfTouchEventDirective,
  BitfClickOutsideDirective,
  BitfClipboardDirective,
  HeroLoaderDirective,
  HeroNotEmptyDirective,
];
const SHARED_BITF_COMPONENTS = [];
const SHARED_PIPES = [
  BitfFormControlTranslatePipe,
  HeroDatePipe,
  TruncatePipe,
  UserInitialsPipe,
  HeroNumberPipe,
  DateAgoPipe,
];
const PROVIDERS = [[{ provide: FormBuilder, useClass: BitfFormBuilderService }]];

const REMIX_ICONS = {
  RiAddCircleFill,
  RiCloseCircleFill,
  RiCloseLine,
  RiCloseFill,
  RiArticleLine,
  RiErrorWarningLine,
  RiArrowRightLine,
  RiArrowDownSLine,
  RiArrowUpSLine,
  RiArrowDownSFill,
  RiArrowUpSFill,
  RiSearchLine,
  RiCheckLine,
  RiSearch2Line,
  RiNotificationLine,
  RiAddLine,
  RiDonutChartFill,
  RiSettingsLine,
  RiArrowLeftLine,
  RiStackLine,
  RiVipCrownLine,
  RiGitBranchFill,
  RiGitCommitLine,
  RiUserSettingsLine,
  RiLinkUnlink,
  RiCalendarLine,
  RiEditBoxLine,
  RiEdit2Line,
  RiLockLine,
  RiGalleryLine,
  RiMoreFill,
  RiDeleteBin5Line,
  RiFileCopyLine,
  RiExternalLinkLine,
  RiSubtractLine,
  RiRefreshLine,
  RiPlug2Line,
  RiPieChartLine,
  RiGalleryFill,
  RiLogoutCircleLine,
  RiDeleteBin4Line,
  RiFocus3Line,
  RiTimerLine,
  RiDownloadCloudLine,
  RiStarLine,
  RiRestartLine,
  RiStarFill,
  RiLoader2Line,
};

@NgModule({
  imports: [RemixIconModule.configure(REMIX_ICONS), ...SHARED_MODULES],
  exports: [
    RemixIconModule,
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_BITF_COMPONENTS,
    ...SHARED_PIPES,
  ],
  declarations: [
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_BITF_COMPONENTS,
    ...SHARED_PIPES,
    ...MODALS,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class CommonSharedModule {
  // This should only be called inside the CoreModule!
}

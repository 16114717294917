<div class="hero-toast-messages">
  <div *ngFor="let message of toastMessages"
    class="hero-toast-messages-item"
    [class.hero-toast-messages-item-error]="message.type === eBitfUiMessageType.ERROR"
    [class.hero-toast-messages-item-warning]="message.type === eBitfUiMessageType.WARNING"
    [class.hero-toast-messages-item-success]="message.type === eBitfUiMessageType.SUCCESS"
    [class.hero-toast-messages-item-info]="message.type === eBitfUiMessageType.INFO">
    <div class="hero-toast-messages-item-header">
      {{ message.title }}
      <rmx-icon name="close-line"
        class="close-icon"
        (click)="removeMessage(message.id)"></rmx-icon>
    </div>
    <div class="hero-toast-messages-item-content">
      {{ message.message }}
    </div>
  </div>
</div>

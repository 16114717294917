import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { EAccountStatus, EConnectorCategory, EChannelName } from '@enums';
import { IDrvConnectionItem } from '@interfaces';

@Component({
  selector: 'hero-connection-card',
  templateUrl: './connection-card.component.html',
  styleUrls: ['./connection-card.component.scss'],
})
export class ConnectionCardComponent implements OnInit, OnChanges {
  @Input() status: EAccountStatus;
  @Input() channel: EChannelName;
  @Input() category: EConnectorCategory;
  @Input() isSelected = false;
  @Output() connectClick = new EventEmitter<IDrvConnectionItem>();

  EAccountStatus = EAccountStatus;
  isConnecting = true;
  imgSrc: string;

  constructor() {}

  ngOnInit(): void {
    this.updateImgOnLeave();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.verifyStatus(this.status);
    if (changes?.status?.currentValue) {
      this.updateImgOnLeave();
    }
  }

  onConnect() {
    this.isConnecting = true;
    this.connectClick.emit();
    setTimeout(() => {
      if (this.status !== EAccountStatus.TOKEN_GRANTED) {
        this.isConnecting = false;
      }
    }, 10000);
  }

  mouseEnter() {
    if (!this.isSelected && !this.status) {
      this.imgSrc = this.getChannelIcon(this.channel);
    }
  }

  updateImgOnLeave() {
    if (this.isSelected || this.status) {
      this.imgSrc = this.getChannelIcon(this.channel);
    } else {
      this.imgSrc = this.getChannelIcon(this.channel, '-faded');
    }
  }

  trackByFn(index) {
    return index;
  }

  private getChannelIcon(channel: EChannelName, suffix = '') {
    return `assets/images/marketing/${channel.toLowerCase()}${suffix}.svg`;
  }

  private verifyStatus(status: EAccountStatus) {
    this.isConnecting = status === EAccountStatus.TOKEN_GRANTED;
  }
}

import { HttpResponse } from '@angular/common/http';

import { IBitfApiResponse, IBitfApiCount, IBitfApiAction, IBitfApiActionData } from '@interfaces';

// FIXME import this from barrel will break the app
import { BitfRestResponseMapper } from '../rest-parser/bitf-rest-response.mapper';

export abstract class BitfDefaultResponseMapper extends BitfRestResponseMapper {
  constructor() {
    super();
  }

  map<T>(
    apiOutput: HttpResponse<IBitfApiResponse<T | T[]>>,
    selctedModelMapper: string
  ): IBitfApiResponse<T | T[] | IBitfApiCount | IBitfApiActionData | IBitfApiAction> {
    const envelope: IBitfApiResponse<
      T | T[] | IBitfApiCount | IBitfApiActionData | IBitfApiAction
    > = this.getEnvelope<T>(apiOutput);

    if (!envelope || !envelope.content) {
      return envelope;
    }
    let { content } = envelope;

    if (this.isResponsePaged<T>(envelope as IBitfApiResponse<T[]>)) {
      content = (content as T[]).map(item => this.unwrapExtraModelFields(item));
      envelope.content = this.modelInstancesFactory<T>(content, selctedModelMapper);
    } else if (this.isResponseList<T>(envelope as IBitfApiResponse<T[]>)) {
      if (selctedModelMapper === 'actionData') {
        const apiActionData: IBitfApiActionData = { data: content } as IBitfApiActionData;
        envelope.content = apiActionData as any;
      } else {
        content = (content as T[]).map(item => this.unwrapExtraModelFields(item));
        envelope.content = this.modelInstancesFactory<T>(content, selctedModelMapper);
      }
    } else {
      switch (selctedModelMapper) {
        case 'count':
          const apiCount = { count: content as any } as IBitfApiCount;
          envelope.content = apiCount;
          break;
        case 'action':
          const apiAction = { success: content as any } as IBitfApiAction;
          envelope.content = apiAction as any;
          break;
        case 'actionData':
          const apiActionData: IBitfApiActionData = {
            data: content,
          } as IBitfApiActionData;
          envelope.content = apiActionData as any;
          break;
        default:
          content = this.unwrapExtraModelFields(content);
          envelope.content = this.modelInstanceFactory<T>(content, selctedModelMapper);
      }
    }

    return envelope;
  }

  // NOTE: this is just for reference
  // unwrapExtraModelFields(item: any) {
  //   if (!item) {
  //     return;
  //   }
  //   const extraFields = ['embed', 'computedFields'];
  //   extraFields.forEach(extraField => {
  //     if (item[extraField]) {
  //       Object.entries(item[extraField]).forEach(([key, val]) => {
  //         item[key] = val;
  //       });
  //     }
  //   });

  //   // NOTE: don't use delete for performance reason
  //   const returnObj = {};
  //   Object.entries(item).forEach(([key, val]) => {
  //     if (!extraFields.includes(key)) {
  //       returnObj[key] = val;
  //     }
  //   });
  //   return returnObj;
  // }
}

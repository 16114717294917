<div class="hero-search-bar"
  (bitfClickOutside)="resultsVisible = false">
  <label for="searchBarId">
    <rmx-icon name="search-2-line"
      class="hero-search-bar__icon"></rmx-icon>
  </label>
  <input #searchInput
    (click)="resultsVisible = true"
    type="text"
    id="searchBarId"
    [placeholder]="'COMMON.LABEL.SEARCH_FOR' | translate" />
  <div class="hero-search-bar__results"
    *ngIf="resultsVisible && status !== eSearchStatus.BEFORE_SEARCH">
    <ng-container [ngSwitch]="status">

      <perfect-scrollbar *ngSwitchCase="eSearchStatus.RESULT_EXISTS"
        [config]="{ suppressScrollX: true }">
        <div class="hero-search-bar__results-scrollbar">
          <ng-container *ngFor="let resultsSet of searchResultsSets">
            <ng-container *ngIf="resultsSet.searchResults?.length">
              <h3>{{ resultsSet.label | translate }}</h3>
              <ul>
                <li *ngFor="let result of resultsSet.searchResults">
                  <a (click)="resultsVisible = false"
                    [routerLink]="result.url">{{ result.label }}</a>
                </li>
              </ul>
            </ng-container>
          </ng-container>
        </div>
      </perfect-scrollbar>

      <div class="hero-search-bar__results-message"
        *ngSwitchCase="eSearchStatus.EMPTY_RESULT">
        {{ 'SEARCH.MESSAGE.NO_RESULTS' | translate }} "{{ keyword }}"
      </div>
      <div class="hero-search-bar__results-message"
        *ngSwitchCase="eSearchStatus.KEYWORD_TO_SHORT">
        {{ 'SEARCH.MESSAGE.KEYWORD_TO_SHORT' | translate }}
      </div>
    </ng-container>
  </div>
</div>
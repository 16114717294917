import { Injectable } from '@angular/core';
import { FormBuilder, ValidatorFn, AbstractControlOptions, AsyncValidatorFn } from '@angular/forms';
import { BitfFormControl } from './bitf-form-control';

@Injectable({
  providedIn: 'root',
})
export class BitfFormBuilderService extends FormBuilder {
  control(
    formState: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ): BitfFormControl {
    return new BitfFormControl(formState, validatorOrOpts, asyncValidator);
  }
}

import { ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';

import { Connector } from '@common/core/models';
import { EChannelName } from '@common/enums';

@Injectable({
  providedIn: 'root',
})
export class ConnectorProviderService {
  constructor(private cfr: ComponentFactoryResolver) {}

  async loadComponent(connector: Connector, vcr: ViewContainerRef, componentInputs) {
    let component;

    if (!connector) {
      return;
    }

    switch (connector.channel) {
      case EChannelName.FACEBOOK:
        const { FacebookComponent } = await import(
          '@web/modules/connectors/connectors/facebook/facebook.component'
        );
        component = FacebookComponent;
        break;
      case EChannelName.GOOGLE_ADS:
        const { GoogleAdsComponent } = await import(
          '@web/modules/connectors/connectors/google-ads/google-ads.component'
        );
        component = GoogleAdsComponent;
        break;
      case EChannelName.GOOGLE_ANALYTICS:
        const { GoogleAnalyticsComponent } = await import(
          '@web/modules/connectors/connectors/google-analytics/google-analytics.component'
        );
        component = GoogleAnalyticsComponent;
        break;
      case EChannelName.MS_BING:
        const { MsBingComponent } = await import(
          '@web/modules/connectors/connectors/ms-bing/ms-bing.component'
        );
        component = MsBingComponent;
        break;
    }

    vcr.clear();
    const componentRef: ComponentRef<any> = vcr.createComponent(this.cfr.resolveComponentFactory(component));

    componentRef.instance.customerAdvAccounts = componentInputs.customerAdvAccounts;
    componentRef.instance.accountDisconnected = componentInputs.accountDisconnected;
    componentRef.instance.accountConnected = componentInputs.accountConnected;

    return componentRef;
  }
}

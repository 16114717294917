import { IBitfPartData } from './bitf-api-call-state.interfaces';
import { BitfApiRequestPart } from './bitf-api-request-part';

import { EBitfApiRequestPartsNames } from '@enums';

export class BitfPaginationApiRequestPart extends BitfApiRequestPart {
  constructor(
    public key: string,
    public initialPagination: { page?: number; size?: number; paginationInfo?: string },
    public options?: { pageSizeOptions?: number[]; pageIndexOffset?: number }
  ) {
    super(key, EBitfApiRequestPartsNames.PAGINATION, { data: initialPagination });
  }

  build() {
    const page = this.data.page;
    const size = this.data.size;
    this.part = {
      ...(page !== undefined && { page }),
      ...(size !== undefined && { size }),
    };

    if (this.data.paginationInfo || this.data.paginationInfo === '') {
      this.part.paginationInfo = this.data.paginationInfo;
    }
  }

  resetPagination() {
    this.data = { ...this.initialPagination };
  }
}

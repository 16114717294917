import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';

import { ApiCallStateService } from '@services';
import { EApiCallStateNames, EApiRequestPartKeys } from '@enums';

@Component({
  selector: 'hero-date-range-filter',
  templateUrl: './date-range-filter.component.html',
  styleUrls: ['./date-range-filter.component.scss'],
})
export class DateRangeFilterComponent implements OnInit {
  @Input() apiCallStateNames: EApiCallStateNames[];

  dateRangeControl: FormControl = new FormControl();

  formGroup: FormGroup;

  subscription: Subscription = new Subscription();

  constructor(private apiCallStateService: ApiCallStateService) {}

  ngOnInit(): void {
    this.initFormGroup();
  }

  private initFormGroup() {
    const dates = this.getRequestPart(this.apiCallStateNames[0]).formValue;
    this.formGroup = new FormGroup({ dates: new FormControl(dates) });

    this.subscription.add(
      this.formGroup.valueChanges.subscribe(value => {
        const {
          dates: { startDate, endDate },
        } = value;

        if (!startDate || !endDate) {
          return;
        }
        this.onDateChange(startDate, endDate);
      })
    );
  }

  private getRequestPart(apiCallStateName: EApiCallStateNames): BitfApiRequestPart {
    return this.apiCallStateService.getRequestPart(apiCallStateName, EApiRequestPartKeys.DATE_RANGE_FILTER);
  }

  private onDateChange(startDate: Date, endDate: Date) {
    this.apiCallStateNames.forEach(apiCallStateName => {
      this.getRequestPart(apiCallStateName).formValue = {
        startDate,
        endDate,
      };
      this.apiCallStateService.setStore(() => {}, apiCallStateName);
    });
  }
}
